import GumShoe from '../vendors/GumShoe';
import { calcOffset } from '../helpers';

export default class Anchors {
    static init() {
        this.sticky()
        this.anchors()
        this._handleSelect()
    }


    static sticky() {
        const stickyElm = document.querySelector('.singleInstitute__anchors');
        const header = document.querySelector('header');

        if (!stickyElm || !header) return;

        const updateSticky = () => {
            const rect = stickyElm.getBoundingClientRect();
            const isSticky = rect.top <= (header.offsetHeight + 1) && rect.bottom > 0;
            stickyElm.classList.toggle('isSticky', isSticky);
            header.classList.toggle('anchorsIsSticky', isSticky);
        };

        window.addEventListener('resize', updateSticky);
        window.addEventListener('scroll', updateSticky);
    }

    static anchors() {
        if (!document.querySelector('.singleInstitute__anchors')) {
            return;
        }

        new GumShoe('.singleInstitute__anchors a', {
            reflow: true,
            offset: () => calcOffset() + 100
        });
    }

    /**
    * Handle select.
    *
    * @private
    */
    static _handleSelect() {
        const select = document.querySelector('.antipodes-anchor-block__select');
        const selectItems = document.querySelector('.antipodes-anchor-block__select-items');

        if (!select || !selectItems) {
            return;
        }
        const openSelect = () => {
            select.classList.add('active');
            selectItems.style.maxHeight = `${selectItems.scrollHeight}px`;
        };

        const closeSelect = () => {
            select.classList.remove('active');
            selectItems.style.maxHeight = '0px';
        };

        const handleSelectTitleClick = (e) => {
            e.stopPropagation();

            const isActive = select.classList.contains('active');

            if (!isActive) {
                openSelect();
            } else {
                closeSelect();
            }
        };

        const handleSelectItemClick = (li) => {
            const newText = li.querySelector('a').innerText;
            document.querySelector('.antipodes-anchor-block__select__title__selected span').innerText = newText;
            closeSelect();
        };

        const handleScroll = () => {
            const headerHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
            const sticky = select.getBoundingClientRect();

            if (sticky.top <= headerHeight) {
                select.classList.add('sticky');
                select.classList.remove('active');
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        document.querySelector('.antipodes-anchor-block__select__title').addEventListener('click', handleSelectTitleClick);

        document.querySelectorAll('.antipodes-anchor-block__select__items li').forEach((li) => {
            li.addEventListener('click', () => handleSelectItemClick(li));
        });

        new GumShoe('.antipodes-anchor-block__select-items a', {
            reflow: true,
            offset: () => calcOffset() + 180
        });
    }
}