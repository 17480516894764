export default class SearchMaps {
    // Static properties for DOM elements
    static locatorSection = document.getElementById("locator-input-section");
    static input = document.getElementById("autocomplete");
    static apiKey = '';

    // Initialize the Locator class
    static init(apiKey = null) {
        this.apiKey = apiKey;
        if (this.locatorSection == null || this.input == null) {
            return;
        }

        const locatorButton = document.getElementById("locator-button");
        locatorButton.addEventListener("click", SearchMaps.locatorButtonPressed);

        // Initialize Google Maps Autocomplete
        SearchMaps.initializeAutocomplete();

        // Initialize Isotope
        this.initializeIsotope();
        this.initializeIsotopePopup();

    }

    // Initialize Google Maps Autocomplete with default bounds
    static initializeAutocomplete() {
        const defaultBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(45.4215296, -75.6971931)
        );

        const options = {
            bounds: defaultBounds,
        };

        const autocomplete = new google.maps.places.Autocomplete(SearchMaps.input, options);

        // Add listener for place selection in the input field
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                SearchMaps.dispatchSearchCompleteEvent(lat, lng);
                SearchMaps.disableInput();
            } else {
                alert("No details available for input: '" + place.name + "'");
            }
        });
    }

    // Disable input field after search
    static disableInput() {
        SearchMaps.input.disabled = true;
        const tagContainer = document.getElementById('locator-input-section');
        const tag = document.createElement('div');
        tag.className = 'search-tag';

        const tagText = document.createElement('span');
        tagText.textContent = SearchMaps.input.value;
        SearchMaps.input.value = 'x';

        const removeButton = document.createElement('span');
        removeButton.className = 'remove-tag';
        removeButton.textContent = '×';
        removeButton.onclick = function () {
            SearchMaps.input.value = '';
            tagContainer.removeChild(tag);
            SearchMaps.input.disabled = false; // Re-enable the input when the tag is removed
        };

        tag.appendChild(tagText);
        tag.appendChild(removeButton);
        tagContainer.appendChild(tag);
    }

    // Handle locator button press
    static locatorButtonPressed() {
        SearchMaps.locatorSection.classList.add("loading");

        navigator.geolocation.getCurrentPosition(
            (position) => {
                SearchMaps.getUserAddressBy(position.coords.latitude, position.coords.longitude);
            },
            (error) => {
                SearchMaps.locatorSection.classList.remove("loading");
                let errorMessage = "La localisation a été refusée :( Veuillez ajouter votre adresse manuellement.";
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errorMessage = "La permission de localisation a été refusée. Veuillez activer les services de localisation et réessayer.";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        errorMessage = "Les informations de localisation sont indisponibles. Veuillez vérifier les paramètres de votre appareil.";
                        break;
                    case error.TIMEOUT:
                        errorMessage = "La demande de localisation a expiré. Veuillez réessayer.";
                        break;
                    case error.UNKNOWN_ERROR:
                        errorMessage = "Une erreur inconnue est survenue lors de la récupération de la localisation.";
                        break;
                }
                alert(errorMessage);
            }
        );
    }

    // Fetch user address using latitude and longitude
    static getUserAddressBy(lat, lng) {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCZGTtC-Z9pmpXy-inncmX5Uu7fPwD3ipo`)
            .then(response => response.json())
            .then(data => {
                console.log('Geocode API Response:', data);
                if (data.status === "OK") {
                    const address = data.results[0].formatted_address;
                    SearchMaps.setAddressToInputField(address);
                    // Dispatch custom event with lat and lng
                    SearchMaps.dispatchSearchCompleteEvent(lat, lng);
                } else {
                    SearchMaps.locatorSection.classList.remove("loading");
                    let errorMessage = "Impossible de récupérer l'adresse. Veuillez réessayer.";
                    alert(errorMessage);
                }
            })
            .catch(error => {
                SearchMaps.locatorSection.classList.remove("loading");
                let errorMessage = "Une erreur est survenue lors de la récupération de l'adresse.";

                console.log('Error:', error);

                // Check the error type and display appropriate message
                switch (error.message) {
                    case 'Failed to fetch':
                        errorMessage = "La connexion au serveur a échoué. Veuillez vérifier votre connexion internet.";
                        break;
                    case 'NetworkError when attempting to fetch resource.':
                        errorMessage = "Problème de réseau lors de la récupération des données. Veuillez réessayer.";
                        break;
                    default:
                        errorMessage = "Une erreur inconnue est survenue. Veuillez vérifier votre connexion et réessayer.";
                        break;
                }
                alert(errorMessage);
            });
    }


    // Set the fetched address to the input field
    static setAddressToInputField(address) {
        SearchMaps.input.value = address;
        SearchMaps.locatorSection.classList.remove("loading");
        SearchMaps.disableInput();
    }

    // Dispatch a custom event with latitude and longitude
    static dispatchSearchCompleteEvent(lat, lng) {
        const searchEvent = new CustomEvent('searchComplete', {
            detail: { lat, lng }
        });
        document.dispatchEvent(searchEvent);
    }

    static initializeIsotope() {
        const grid = document.querySelector('.institutesMapsWithSearch__institutes');
        const checkbox = document.querySelector('.institutesMapsWithSearch__switch');

        if (grid) {

            let iso = new Isotope(grid, {
                itemSelector: '.institutesMapsWithSearch__institute',
                layoutMode: 'vertical',
                transitionDuration: 0,
                getSortData: {
                    number: function (itemElem) {
                        const element = itemElem instanceof HTMLElement ? itemElem : itemElem.element;
                        return parseInt(element.querySelector('.number').textContent);
                    },
                }
            });

            // Handle resize event to re-sort items
            addEventListener("resize", () => {
                iso.arrange({ sortBy: 'number' });
            });

            // Create a custom event for checkbox toggle
            const checkboxToggleEvent = new Event('checkboxToggle');

            // Dispatch custom event on checkbox state change
            checkbox.addEventListener('change', () => {
                checkbox.dispatchEvent(checkboxToggleEvent);
            });

            // Listen for the custom event to filter items based on checkbox state
            checkbox.addEventListener('checkboxToggle', () => {
                if (checkbox.checked) {
                    iso.arrange({ filter: '.institut-with-balontherapie' });
                } else {
                    iso.arrange({ filter: '*' }); // Show all items when unchecked
                }
            });
        }
    }

    static initializeIsotopePopup() {
        const grid = document.querySelector('.institutesMapsWithSearch--popup .institutesMapsWithSearch__institutes');
        const checkbox = document.querySelector('.institutesMapsWithSearch--popup .institutesMapsWithSearch__switch');

        if (grid) {

            let iso = new Isotope(grid, {
                itemSelector: '.institutesMapsWithSearch--popup .institutesMapsWithSearch__institute',
                layoutMode: 'vertical',
                transitionDuration: 0,
                getSortData: {
                    number: function (itemElem) {
                        const element = itemElem instanceof HTMLElement ? itemElem : itemElem.element;
                        return parseInt(element.querySelector('.number').textContent);
                    },
                }
            });

            // Handle resize event to re-sort items
            addEventListener("resize", () => {
                iso.arrange({ sortBy: 'number' });
            });

            // Create a custom event for checkbox toggle
            const checkboxToggleEvent = new Event('checkboxToggle');

            // Dispatch custom event on checkbox state change
            checkbox.addEventListener('change', () => {
                checkbox.dispatchEvent(checkboxToggleEvent);
            });

            // Listen for the custom event to filter items based on checkbox state
            checkbox.addEventListener('checkboxToggle', () => {
                if (checkbox.checked) {
                    iso.arrange({ filter: '.institut-with-balontherapie' });
                } else {
                    iso.arrange({ filter: '*' }); // Show all items when unchecked
                }
            });
        }
    }
}
