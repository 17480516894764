/**
 * DOM Animations
 */
 export default class CardSlide {

	/**
	 * SlideUp
	 *
	 * @param {HTMLElement} element
	 * @param {Number} duration
	 * @returns {Promise<boolean>}
	 */
	static cardSlideUp(element, duration = 500) {
		return new Promise(resolve => {
			element.style.height = element.getAttribute('data-height') + 'px';
			element.style.transitionDuration = duration + 'ms';
			element.style.height = '350px';

			setTimeout(() => {
				element.removeAttribute('style');
				resolve(false);
			}, duration);
		});
	}

	/**
	 * SlideDown
	 *
	 * @param {HTMLElement} element
	 * @param {Number} duration
	 * @returns {Promise<boolean>}
	 */
	static cardSlideDown(element, duration = 500) {
		return new Promise(resolve => {
			const initialHeight = element.offsetHeight;
            element.style.maxHeight = 'initial';
			const height = element.offsetHeight;
            element.style.height = initialHeight + 'px';
			element.offsetHeight;
			element.style.boxSizing = 'border-box';
			element.style.transitionProperty = 'height';
			element.style.transitionDuration = duration + 'ms';
            element.style.height = height + 'px';

			setTimeout(() => {
				element.setAttribute('data-height', height);
				resolve(true);
			}, duration);
		});
	}
}