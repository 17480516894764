import { throttle } from '../helpers';

export default class BackToTop extends HTMLElement {

	constructor() {
		super();
		this.settings = {
			appearScrollAmount: this.getAttribute('appear-scroll-amount') || 0
		};

		this.onScroll = throttle(this.onScroll.bind(this), 100);
		this.addEventListener('click', e => {
			e.preventDefault();
			this.scrollToTop();
		});
	}

	connectedCallback() {
		// Disable back to top button from screen reader. They already have a shortcut to do this
		this.setAttribute('aria-hidden', 'true');

		if (this.settings.appearScrollAmount > 0) {
			window.addEventListener('scroll', this.onScroll);
		} else {
			this.classList.add('is-active');
		}
	}

	/**
	 * Remove scrolling event if the back to top doesn't exist anymore
	 */
	disconnectedCallback() {
		window.removeEventListener('scroll', this.onScroll);
	}

	/**
	 * Scroll to top on click on the back to top
	 */
	scrollToTop(duration = 500) {
		this.dispatchEvent(new Event('backToTopScrollStart'));
		window.scrollTo({
			top: 0,
			behavior: 'auto'
		})
		this.dispatchEvent(new Event('backToTopScrollEnd'));
	}

	/**
	 * On scroll, add is-active class to the element
	 * if we go further appearScrollAmount
	 */
	onScroll() {
		const scroll = window.scrollY;

		if (scroll > this.settings.appearScrollAmount) {
			this.classList.add('is-active');
			document.body.classList.add('backToTop--isActive');
			this.dispatchEvent(new Event('backToTopIsActive'));
		} else {
			this.classList.remove('is-active');
			document.body.classList.remove('backToTop--isActive');
			this.dispatchEvent(new Event('backToTopIsHidden'));
		}
	}

}