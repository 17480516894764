/**
 * $ helper (instead of jQuery)
 */
export const $ = (el) => jQuery(el);

/**
 * Set header height in a css variable
 */
export const setHeaderHeight = () => {
	const {height} = document.querySelector('header').getBoundingClientRect();
	document.documentElement.style.setProperty('--header-height', `${height}px`);
};

/**
 * Is touch device ?
 *
 * @type {boolean}
 */
// @formatter:off
export const isTouchDevice = (('ontouchstart' in window)
                              || (navigator.MaxTouchPoints > 0)
                              || (navigator.msMaxTouchPoints > 0));
// @formatter:on

/**
 * Add multiple events to an element
 *
 * @param element
 * @param events
 * @param handler
 */
export const addMultipleEventListener = (element, events, handler) => events.forEach(e => element.addEventListener(e, handler));

/**
 * Call callback function after a certain delay
 *
 * @param callback
 * @param delay
 * @returns {function(...[*]=)}
 */
export const debounce = (callback, delay) => {
	let timer;
	return function () {
		let args    = arguments,
		    context = this;

		clearTimeout(timer);
		timer = setTimeout(() => callback.apply(context, args), delay);
	};
};

/**
 * @param {string} str
 * @returns {DocumentFragment}
 */
export const strToDom = (str) => document.createRange().createContextualFragment(str);

/**
 * Avoid callback call too many time
 *
 * @param callback
 * @param delay
 * @returns {function(...[*]=)}
 */
export const throttle = (callback, delay) => {
	let last,
	    timer;

	return function () {
		let context = this,
		    now     = +new Date(),
		    args    = arguments;

		if (last && now < last + delay) {
			clearTimeout(timer);
			timer = setTimeout(function () {
				last = now;
				callback.apply(context, args);
			}, delay);
		} else {
			last = now;
			callback.apply(context, args);
		}
	};
};

/**
 * Unwrap an element
 *
 * @param wrapper
 */
export const unwrap = wrapper => {
	// place childNodes in document fragment
	const docFrag = document.createDocumentFragment();
	while (wrapper.firstChild) {
		const child = wrapper.removeChild(wrapper.firstChild);
		docFrag.appendChild(child);
	}

	// replace wrapper with document fragment
	wrapper.parentNode.replaceChild(docFrag, wrapper);
};

/**
 * Wrap and HTML structure around an element
 *
 * @param el
 * @param wrapper
 */
export const wrap = (el, wrapper) => {
	el.parentNode.insertBefore(wrapper, el);
	wrapper.appendChild(el);
	return wrapper;
};

/**
 * Wrap all
 *
 * @param target
 * @param wrapper
 * @returns {HTMLDivElement}
 */
export const wrapAll = (target, wrapper = document.createElement('div')) => {
	;[...target.childNodes].forEach(child => wrapper.appendChild(child));
	target.appendChild(wrapper);
	return wrapper;
};

export const calcOffset = () => {
	let defaultOffset  = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--anchorBlockLinksOffset')) || 20,
	    $adminBar      = document.querySelector('#wpadminbar'),
	    //@formatter:off
	    adminBarHeight = ($adminBar ? $adminBar.getBoundingClientRect().height : 0),
	    //@formatter:on
	    headerHeight   = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));

	if (!headerHeight) {
		headerHeight = 0;
	}

	return defaultOffset + headerHeight + adminBarHeight;
}

/**
 * Get an array of the focusable elements
 *
 * @returns {string[]}
 */
export const getFocusableElements = () =>
	[
		'a',
		'button:not([disabled])',
		'input:not([disabled])',
		'select:not([disabled])',
		'textarea:not([disabled])',
		'[tabindex]:not([tabindex="-1"])'
	];

/**
 * Return top and left offset of an element
 *
 * @param el
 * @returns {{top: number, left: number}}
 */
export const offsetOf = el => {
	let rect   = el.getBoundingClientRect(),
	    bodyEl = document.body;

	return {
		top: rect.top + bodyEl.scrollTop,
		left: rect.left + bodyEl.scrollLeft
	};
};
