import 'regenerator-runtime/runtime';

export default class DataSEO {

	/**
	 * Get data seo from API.
	 *
	 * @private
	 */
	async _getDataSeo() {
		return await fetch('https://www.institut-kinesitherapie.paris/wp-json/institut-kine/v1/data-seo-names/');
	}


	/**
	 * Initialization
	 */
	init() {
		this._getDataSeo()
		.then(response => response.json())
		.then(data => {
			if (data.status !== 200) {
				return;
			}

			for (const seoName in data.response) {
				const [dataAttribute, name] = [seoName, data.response[seoName]];
				const $dataAttributeButtons = document.querySelectorAll(`[${dataAttribute}], [${dataAttribute}] *`);

				if ($dataAttributeButtons && window.dataLayer) {
					[...$dataAttributeButtons].forEach($dataAttributeButton => {
						$dataAttributeButton.addEventListener('click', () => {
							window.dataLayer.push({
								'event': 'data_seo_click',
								'seo_name': name
							});
						});
					});
				}
			}
		})
		.catch(e => console.error(e));
	}
}