export default class InteractivePointsModule {

	constructor(module) {
		this.ACTIVE_AREA_CLASS = 'isActive';
		this.module = module;
		this.points = this.module.querySelectorAll('.interactiveModule__content--points__image__points > div');
		this.currentPoint = 0;
		this.init();
	}

	init() {
		let animation = '';
		if (document.body.classList.contains('page-template-home-page')) {
			animation = this.animation();
		}

		[...this.points].forEach(point => {
			point.addEventListener('mouseenter', () => {
				const activePoint = this.module.getElementsByClassName(this.ACTIVE_AREA_CLASS)[0];

				if (!activePoint) {
					return;
				}

				activePoint.classList.remove(this.ACTIVE_AREA_CLASS);
				clearInterval(animation);
			});

			point.addEventListener('touchstart', e => {
				const activePoint = this.module.getElementsByClassName(this.ACTIVE_AREA_CLASS)[0];

				if (!activePoint) {
					return;
				}

				if (e.target.hasAttribute('data-point-link') || e.target.hasAttribute('data-point-index')) {
					activePoint.classList.remove(this.ACTIVE_AREA_CLASS);
					clearInterval(animation);
				}
			});

			if (document.body.classList.contains('page-template-home-page')) {
				point.addEventListener('mouseleave', () => {
					animation = this.animation();
				});
			}
		});
	}

	animation() {
		this.points[this.currentPoint].classList.add(this.ACTIVE_AREA_CLASS);
		return setInterval(() => {
			const activePoint = this.module.getElementsByClassName(this.ACTIVE_AREA_CLASS)[0];
			this.currentPoint = this.points[this.currentPoint + 1] ? this.currentPoint + 1 : 0;
			activePoint.classList.remove(this.ACTIVE_AREA_CLASS);
			this.points[this.currentPoint].classList.add(this.ACTIVE_AREA_CLASS);
		}, 2500);
	}
}