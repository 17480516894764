import TrackingEvent from "./TrackingEvent";

export default class Tracking {

	static init() {
		window.dataLayer = window.dataLayer || [];

		//====================== DATA SEO POPUP DOCTOLIB ======================

		// var mySelect = document.getElementById("popup-select");
		// let doctolib;

		// if (mySelect) {
		// 	var selectedOption = mySelect.options[mySelect.selectedIndex];
		// 	doctolib = selectedOption.getAttribute("dataseo");

		// 	window.doctolib = doctolib;

		// 	mySelect.addEventListener("change", () => {
		// 		var selectedOption = mySelect.options[mySelect.selectedIndex];
		// 		doctolib = selectedOption.getAttribute("dataseo");

		// 		window.doctolib = doctolib;
		// 	});
		// }

		// const $elements = document.querySelectorAll("#popup-link");
		// if ($elements) {
		// 	$elements.forEach((el) => {
		// 		el.addEventListener("click", () => {
		// 			dataLayer.push({
		// 				'event': "clic_doctolib",
		// 				'action': doctolib
		// 			});
		// 		});
		// 	});
		// }

		//====================== END DATA SEO POPUP DOCTOLIB ======================

		//====================== DATA SEO POPUP PHONE ======================

		// var mySelect2 = document.getElementById("popup-select-2");
		// let phoneSeoPopup;
		// if (mySelect2) {
		// 	var selectedOption = mySelect2.options[mySelect2.selectedIndex];
		// 	phoneSeoPopup = selectedOption.getAttribute("dataseo");

		// 	window.phoneSeoPopup = phoneSeoPopup;

		// 	mySelect2.addEventListener("change", () => {
		// 		var selectedOption = mySelect2.options[mySelect2.selectedIndex];
		// 		phoneSeoPopup = selectedOption.getAttribute("dataseo");

		// 		window.phoneSeoPopup = phoneSeoPopup;
		// 	});
		// }


		// const $elements2 = document.querySelectorAll("#popup-link-2");
		// if ($elements2) {
		// 	$elements2.forEach((el) => {
		// 		el.addEventListener("click", () => {
		// 			dataLayer.push({
		// 				'event': "clic_tel",
		// 				'action': phoneSeoPopup
		// 			});
		// 		});
		// 	});
		// }

		//====================== END DATA SEO POPUP DOCTOLIB ======================

		//====================== DATA SEO DOCTOLIB ======================

		var $doctolibPopupSeo = document.querySelectorAll("[data-popup-doctolib]");

		if ($doctolibPopupSeo) {
			$doctolibPopupSeo.forEach((el) => {
				const buttonLocation = el.getAttribute('button-location');

				el.addEventListener("click", () => {
					dataLayer.push({
						'event': "clic_popup_doctolib",
						'button_location': buttonLocation,
					});
				});
			});
		}

		var $doctolibButtonSeo = document.querySelectorAll("[data-seo-doctolib]");

		if ($doctolibButtonSeo) {
			$doctolibButtonSeo.forEach((el) => {
				const zoneSite = el.hasAttribute('doctolib-popup') ? "popup" : "corps_page";
				const centerName = el.getAttribute("center-name") || null;
				const praticienName = el.getAttribute("praticien-name") || null;

				el.addEventListener("click", () => {
					dataLayer.push({
						'event': "clic_doctolib",
						'center_name': centerName,
						'zone_site': zoneSite,
						'praticien_name': praticienName
					});
				});
			});
		}

		//====================== END DATA SEO DOCTOLIB ======================

		//====================== DATA SEO LOCATOR ======================

			var $locator = document.querySelectorAll('#locator-button');

			if ($locator) {
				$locator.forEach((el) => {
					const zoneSite = el.hasAttribute('doctolib-popup') ? "popup" : "corps_page";

					el.addEventListener("click", () => {
						dataLayer.push({
							'event': "clic_geoloc_popup",
							'zone_site': zoneSite,
						});
					});
				});
			}

		//====================== DATA SEO LOCATOR ======================

		//====================== DATA SEO PHONE ======================

		var $phonePopupSeo = document.querySelectorAll('[data-phones-button]');

		if ($phonePopupSeo) {
			$phonePopupSeo.forEach((el) => {
				const buttonLocation = el.getAttribute("button-location") || null;

				el.addEventListener("click", () => {
					dataLayer.push({
						'event': "clic_popup_tel",
						'button_location': buttonLocation
					});
				});
			});
		}

		var $phoneSeo = document.querySelectorAll('a[href^="tel:"]:not(div.popupPhone__phone a[href^="tel:"])');

		if ($phoneSeo) {
			$phoneSeo.forEach((el) => {
				const centerName = el.getAttribute("center-name") || null;
				const zoneSite = el.hasAttribute('doctolib-popup') ? "popup" : "corps_page";

				el.addEventListener("click", () => {
					dataLayer.push({
						'event': "clic_tel",
						'center_name': centerName,
						'zone_site': zoneSite,
					});
				});
			});
		}

		var $phoneHomeSeo = document.querySelectorAll('div.popupPhone__phone a[href^="tel:"]');

		if ($phoneHomeSeo) {
			$phoneHomeSeo.forEach((el) => {
				const centerName = el.getAttribute("center-name") || null;
				const zoneSite = el.hasAttribute('phone-popup') ? "popup-accueil" : null;

				el.addEventListener("click", () => {
					dataLayer.push({
						'event': "clic_tel",
						'zone_site': zoneSite,
					});
				});
			});
		}

		//====================== END DATA SEO DOCTOLIB ======================

		//====================== DATA SEO ADDRESS ======================

		var $addressSeo = document.querySelectorAll('a[href^="https://maps.app"]');

		if ($addressSeo) {
			$addressSeo.forEach((el) => {
				const centerName = el.getAttribute("center-name") || null;
				const zoneSite = el.hasAttribute('doctolib-popup') ? "popup" : "corps_page";

				el.addEventListener("click", () => {
					dataLayer.push({
						'event': "clic_adress",
						'center_name': centerName,
						'zone_site': zoneSite,
					});
				});
			});
		}

		//====================== END DATA SEO ADDRESS ======================

		TrackingEvent.Click(
			[
				'.authorBlock-footer__mail a'
			],
			{
				'event': "clic_mail"
			}
		)

		// TrackingEvent.Click(
		// 	[
		// 		'.popup__teleconsultation__appointment-button'
		// 	],
		// 	{
		// 		'event': "clic_doctolib_teleconsultation"
		// 	}
		// )

		// TrackingEvent.Click(
		// 	[
		// 		'.popup__teleconsultation:not(.isShow) .popup__teleconsultation__title'
		// 	],
		// 	{
		// 		'event': "clic_teleconsultation"
		// 	}
		// )

		// TrackingEvent.Click(
		// 	[
		// 		'.cookie-notice-close'
		// 	],
		// 	{
		// 		'event': "clic_fermeture_cookies"
		// 	}
		// )

		// TrackingEvent.Click(
		// 	[
		// 		'.cookies-detail-btn'
		// 	],
		// 	{
		// 		'event': "clic_cliquez_ici_cookies"
		// 	}
		// )

		TrackingEvent.Click(
			[
				'.header_top_responsive_findCenter a[data-seo-findcenter]',
				'.header_right_top a[data-seo-findcenter]'
			],
			{
				'event': "clic_nos_instituts",
				'action': "clic_nos_instituts_header"
			}
		)

		TrackingEvent.Click(
			[
				'.appFooter__findCenter a[data-seo-findcenter]'
			],
			{
				'event': "clic_nos_instituts",
				'action': "nos_instituts_footer"
			}
		)

		document.addEventListener('wpcf7mailsent', function (event) {
			if (event.detail.contactFormId == '5') {
				window.dataLayer.push({
					'center_name': 'paris8',
				});
			} else if (event.detail.contactFormId == '1086') {
				window.dataLayer.push({
					'center_name': 'paris16_trocadero',
				});
			} else if (event.detail.contactFormId == '4424') {
				window.dataLayer.push({
					'center_name': 'paris16_vineuse',
				});
			} else if (event.detail.contactFormId == '7143') {
				window.dataLayer.push({
					'center_name': 'paris15_segur',
				});
			} else if (event.detail.contactFormId == '9871') {
				window.dataLayer.push({
					'center_name': 'ik_boulogne',
				});
			} else if (event.detail.contactFormId == '9868') {
				window.dataLayer.push({
					'center_name': 'paris17_villiers',
				});
			} else if (event.detail.contactFormId == '9870') {
				window.dataLayer.push({
					'center_name': 'paris6_cassette',
				});
			} else if (event.detail.contactFormId == '9873') {
				window.dataLayer.push({
					'center_name': 'koss_paris7',
				});
			} else if (event.detail.contactFormId == '9874') {
				window.dataLayer.push({
					'center_name': 'koss_paris8',
				});
			} else if (event.detail.contactFormId == '9869') {
				window.dataLayer.push({
					'center_name': 'paris9_petrelle',
				});
			} else {
				window.dataLayer.push({
					'center_name': 'chatenay',
				});
			}

		}, true);
	}
}