import Cookie from "./Cookie";

export default class Popup {
  static init() {
    const popup = document.querySelectorAll('.modal-popup');

    if(popup.length !== 1) {
      return;
    }

    const popupOpened = Cookie.read('popupOpened');

    if(!popupOpened) {
      const overlay = document.querySelector('.searchBarOverlay');
      const closeButton = popup[0].querySelector('.modal-popup__closeButton');

      setTimeout(() => {
        popup[0].classList.add('enable');
        overlay.classList.add('visible');
      }, 1000)

      closeButton.addEventListener('click', () => {
        overlay.classList.remove('visible');
        popup[0].classList.remove('enable');
        Cookie.create('popupOpened', true, 1 / 2 /* 12 heures */);
      })

    }
  }
}
