import { initCursors } from "./components/cursor";
import { strToDom, unwrap, wrap, wrapAll } from "./helpers";

/**
 * Handle Slider exceptions
 *
 * @constructor
 */
function SliderException(message) {
  this.name = "SliderException";
  this.message = message;
  this.stack = new Error().stack;
}

SliderException.prototype = Object.create(Error.prototype);
SliderException.prototype.constructor = SliderException;

/**
 * Manage sliders of the website
 */
export default class Sliders {
  /**
   * Ensure Swiper plugin is not missing
   *
   * @returns {boolean}
   */
  static pluginMissing() {
    if (typeof Swiper === "undefined")
      throw new SliderException(
        "Swiper plugin is missing. Please refer to https://swiperjs.com/get-started/"
      );
    return false;
  }

  /**
   * Manage accessibility of slider
   *
   */
  static accessibility() {
    const focusableElementsArray = [
      "[href]",
      "button:not([disabled])",
      "input:not([disabled])",
      "select:not([disabled])",
      "textarea:not([disabled])",
      '[tabindex]:not([tabindex="-1"])',
    ];

    // Aria hidden & focusable elements inner
    const handleAccesibilitySlide = ($slides, slidesPerView) => {
      let activeSlideIndex;

      $slides.map(($slide) => {
        const isActiveSlide = $slide.classList.contains("swiper-slide-active");
        const $focusableElements = Array.from(
          $slide.querySelectorAll(focusableElementsArray)
        );

        $focusableElements.map(($focusableElement) =>
          $focusableElement.setAttribute("tabindex", "-1")
        );

        $slide.setAttribute("aria-hidden", "true");

        if (isActiveSlide) activeSlideIndex = $slides.indexOf($slide);
      });

      // Accessibility attributes for active slides
      for (
        let i = activeSlideIndex;
        i <= activeSlideIndex + (slidesPerView - 1);
        i++
      ) {
        const $slide = $slides[i];
        const $focusableElements = Array.from(
          $slide.querySelectorAll(focusableElementsArray)
        );

        $focusableElements.map(($focusableElement) =>
          $focusableElement.setAttribute("tabindex", "0")
        );

        $slide.setAttribute("aria-hidden", "false");
      }
    };

    return {
      init: function () {
        const $slides = Array.from(this.slides);
        handleAccesibilitySlide($slides, this.params.slidesPerView);
      },
      slideChangeTransitionEnd: function () {
        const $slides = Array.from(this.slides);
        handleAccesibilitySlide($slides, this.params.slidesPerView);

        // Handle Tab on the actual slide
        const focusableElements = this.el
          .querySelector(".swiper-slide-active")
          .querySelectorAll(focusableElementsArray),
          firstFocusableElement = focusableElements[0],
          lastFocusableElement =
            focusableElements[focusableElements.length - 1];

        const handleTabInSlide = (e) => {
          const tab = e.key === "Tab";
          if (!tab) return;

          if (e.shiftKey) {
            e.preventDefault();
            lastFocusableElement.focus();
          } else {
            // Tab
            e.preventDefault();
            firstFocusableElement.focus();
          }

          document.removeEventListener("keydown", handleTabInSlide);
        };

        if (!firstFocusableElement) return;
        document.addEventListener("keydown", handleTabInSlide);
      },
    };
  }

  /**
   * Default slider
   */
  static defaultSlider() {
    const $sliders = document.querySelectorAll(".defaultSlider");

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const $pagination = $slider.querySelector(".defaultSlider__pagination");
        const $navigations = {
          left: $slider.querySelector(".defaultSlider__navigation__left"),
          right: $slider.querySelector(".defaultSlider__navigation__right"),
        };

        new Swiper($slider.querySelector(".swiper-container"), {
          slidesPerView: 1,
          autoHeight: true,
          observer: true,
          observerParents: true,
          pagination: {
            el: $pagination,
            clickable: true,
          },
          navigation: {
            prevEl: $navigations.left,
            nextEl: $navigations.right,
          },
        });
      });
    }
  }

  /**
   *  Home informations slider
   */
  static homeInformations() {
    const $sliders = document.querySelectorAll(".homeInformations__slider");

    if ($sliders.length && !this.pluginMissing()) {

      $sliders.forEach(($slider) => {
        const $pagination = $slider.querySelector(".swiper-pagination");

        const $options = {
          slidesPerView: 1,
          autoHeight: true,
          observer: true,
          observerParents: true,
          spaceBetween: 20,
          pagination: {
            el: $pagination,
            clickable: true,
          }
        }

        let swiper = new Swiper($slider.querySelector(".swiper-container"), $options);

        window.addEventListener('load', () => {
          if (window.innerWidth < 768) {
            swiper.destroy()
            swiper = new Swiper($slider.querySelector(".swiper-container"), $options);
          }
        })

        window.addEventListener('resize', () => {
          if (window.innerWidth < 768) {
            swiper.destroy()
            swiper = new Swiper($slider.querySelector(".swiper-container"), $options);
          }
        })
      });
    }
  }

  /**
   * List blocks slider
   */
  static listBlocksSlider() {
    const $listBlocks = document.querySelectorAll(".listBlocks");
    if ($listBlocks.length > 0 && !this.pluginMissing()) {
      const breakpoint = window.matchMedia("(min-width: 768px)");

      $listBlocks.forEach(($listBlock) => {
        let swiper;

        const enableSwiper = () => {
          [...$listBlock.children].forEach(($children) => {
            const $swiperSlide = document.createElement("div");
            $swiperSlide.classList.add("swiper-slide");
            wrap($children, $swiperSlide);
          });

          const $swiperNavigations = strToDom(
            `
							<div class="listBlocks__navigations">
								<button class="listBlocks__previousSlide"></button>
								<button class="listBlocks__nextSlide"></button>
							</div>
							`
          );
          const $swiperPagination = strToDom(
            `<div class="listBlocks__pagination"></div>`
          );

          let $swiperContainer = document.createElement("div");
          $swiperContainer.classList.add("swiper-container");

          let $swiperWrapper = document.createElement("div");
          $swiperWrapper.classList.add("swiper-wrapper");

          $swiperWrapper = wrapAll($listBlock, $swiperWrapper);
          $swiperContainer = wrap($swiperWrapper, $swiperContainer);

          $listBlock.prepend($swiperNavigations);
          $listBlock.append($swiperPagination);

          swiper = new Swiper($swiperContainer, {
            slidesPerView: 1,
            autoHeight: true,
            navigation: {
              prevEl: $listBlock.querySelector(".listBlocks__previousSlide"),
              nextEl: $listBlock.querySelector(".listBlocks__nextSlide"),
            },
            pagination: {
              el: $listBlock.querySelector(".listBlocks__pagination"),
              clickable: true,
            },
          });
        };

        const breakpointChecker = () => {
          if (breakpoint.matches === true) {
            if (swiper !== undefined) {
              const $swiperContainer =
                $listBlock.querySelector(".swiper-container");
              const $swiperWrapper =
                $listBlock.querySelector(".swiper-wrapper");
              const $swiperSlides =
                $listBlock.querySelectorAll(".swiper-slide");
              const $swiperNavigations = $listBlock.querySelector(
                ".listBlocks__navigations"
              );
              const $swiperPagination = $listBlock.querySelector(
                ".listBlocks__pagination"
              );

              $swiperNavigations.remove();
              $swiperPagination.remove();
              unwrap($swiperContainer);
              unwrap($swiperWrapper);
              [...$swiperSlides].forEach(($swiperSlide) =>
                unwrap($swiperSlide)
              );

              swiper.destroy(true, true);
            }
            return;
          }
          return enableSwiper();
        };

        try {
          breakpoint.addListener(breakpointChecker);
        } catch (e) {
          breakpoint.addEventListener("change", breakpointChecker);
        }
        breakpointChecker();
      });
    }
  }

  /**
   * Institutes Slider
   */
  static institutesSlider() {
    const $sliders = document.querySelectorAll(".institutesSlider");
    if (!$sliders) return;

    $sliders.forEach(($slider) => {

      new Swiper(
        $slider.querySelector(".institutesSlider__slider .swiper-container"),
        {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: true,
          loopAdditionalSlides: 1,
          navigation: {
            prevEl: document.querySelector('.institutesSlider__slider__navigationPrevious') || null,
            nextEl: document.querySelector('.institutesSlider__slider__navigationNext') || null,
          },
          breakpoints: {
            600: {
              slidesPerView: 1.5,
              spaceBetween: 45,
            },
          },
        }
      );
    });
  }

  static instituteCards() {
    const $sliders = document.querySelectorAll(".institutesCards");
    if ($sliders.length && !this.pluginMissing()) {
      [...$sliders].forEach(($slider) => {
        const breakpoint = window.matchMedia("(min-width: 1024px)");
        let swiper;

        const recalcSliders = () =>
          [...$slider.querySelectorAll(".swiper-container")].forEach(
            ($swiperSlider) => {
              $swiperSlider.swiper ? $swiperSlider.swiper.update() : null;
            }
          );

        const enableSwiper = () => {
          swiper = new Swiper($slider, {
            slidesPerView: 1,
            spaceBetween: 10,
            loop: true,
            loopAdditionalSlides: 1,
            navigation: {
              prevEl: document.querySelector('.btn-prev-instituteCard') || null,
              nextEl: document.querySelector('.btn-next-instituteCard') || null,
            },
            pagination: {
              el: '.institutesSlider__pagination',
              clickable: true,
            },

            breakpoints: {
              600: {
                slidesPerView: 1.5,
                spaceBetween: 45,
              },
            },
          });
          recalcSliders();
        };

        const breakpointChecker = () => {
          if (
            breakpoint.matches === true &&
            $slider.classList.contains("swiper-container")
          ) {
            if (
              $slider.parentNode.classList.contains("institutesSlider__slider")
            ) {
              unwrap($slider.parentNode);
            }
            $slider.classList.remove("swiper-container");
            [...$slider.querySelectorAll(".swiper-slide-duplicate")].forEach(
              ($duplicateSlide) => $duplicateSlide.remove()
            );
            unwrap($slider.querySelector(":scope > .swiper-wrapper"));
            [...$slider.querySelectorAll(":scope > .swiper-slide")].forEach(
              ($swiperSlide) => unwrap($swiperSlide)
            );
            if (swiper !== undefined) {
              swiper.destroy(true, true);
            }
            recalcSliders();
          } else if (breakpoint.matches === false) {
            if (!$slider.classList.contains("swiper-container")) {
              const $instituteSlider = document.createElement("div");
              $instituteSlider.classList.add("institutesSlider__slider");
              wrap($slider, $instituteSlider);
              $slider.classList.add("swiper-container");

              [...$slider.querySelectorAll(".instituteCard")].forEach(
                ($instituteCard) => {
                  const $swiperSlide = document.createElement("div");
                  $swiperSlide.classList.add("swiper-slide");
                  wrap($instituteCard, $swiperSlide);
                }
              );

              const $swiperWrapper = document.createElement("div");
              $swiperWrapper.classList.add("swiper-wrapper");
              wrapAll($slider, $swiperWrapper);
            }
            return enableSwiper();
          }
        };

        breakpoint.addListener(breakpointChecker);
        breakpointChecker();
      });
    }
  }

  /**
   * Slider Institut
   */
  static institutSlider() {
    const $sliders = document.querySelectorAll(".institutSlider");

    if ($sliders.length && !this.pluginMissing()) {
      var galleryThumbs = new Swiper(".institutThumbs", {
        spaceBetween: 13,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
      var galleryTop = new Swiper(".institutSlider", {
        spaceBetween: 0,
        speed: 700,
        autoplay: {
          delay: 2500,
        },
        navigation: {
          nextEl: ".slider_navigation_next",
          prevEl: ".slider_navigation_prev",
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      });
    }
  }

  /**
   * Slider Soins
   */
  static soinsSlider() {
    const $sliders = document.querySelectorAll(".sliderSoins__slider");

    if ($sliders.length && !this.pluginMissing()) {
      const swiper = new Swiper(".sliderSoins__slider .swiper-container", {
        spaceBetween: 0,
        navigation: {
          nextEl: ".slider_navigation_next",
          prevEl: ".slider_navigation_prev",
        },
        breakpoints: {
          500: {
            slidesPerView: 2,
          },
          650: {
            slidesPerView: 3,
          },
          1300: {
            slidesPerView: 3.2,
          },
          1600: {
            slidesPerView: 4.2,
          },
        },
      });
    }

    initCursors();
  }

  /**
   * Slider Accompagner
   */
  static accompagnerSlider() {
    const $sliders = document.querySelectorAll(".sliderAccompagner");

    if ($sliders.length && !this.pluginMissing()) {
      const swiper = new Swiper(".sliderAccompagner .swiper-container", {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 700,
        autoplay: {
          delay: 2500,
        },
        pagination: {
          el: ".sliderAccompagner-pagination",
          type: "progressbar",
        },
      });
    }

    initCursors();
  }

  /**
   * Text media slider
   */
  static textMediaSlider() {
    const $sliders = document.querySelectorAll(".textMedias__media__slider");

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const slidesLength = $slider.querySelectorAll(".swiper-slide").length;

        new Swiper($slider, {
          slidesPerView: 1,
          watchOverflow: true,
          navigation: {
            prevEl: $slider.querySelector(
              ".textMedias__media__slider__navigations__previous"
            ),
            nextEl: $slider.querySelector(
              ".textMedias__media__slider__navigations__next"
            ),
          },
          loop: slidesLength > 1,
        });
      });
    }
  }

  /**
   * Blocks list
   */
  static blockList() {
    const $sliders = document.querySelectorAll(".blocksList .swiper-container");

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        new Swiper($slider, {
          slidesPerView: 1,

          loop: true,
          pagination: {
            el: '.blocksList__pagination',
            clickable: true
          },
          navigation: {
            prevEl: $slider.querySelector(
              ".blocksList__navigations__previous"
            ),
            nextEl: $slider.querySelector(
              ".blocksList__navigations__next"
            ),
          },
          breakpoints: {
            900: {
              slidesPerView: 3,
              spaceBetween: 17,
            },
            580: {
              slidesPerView: 2,
              spaceBetween: 17,
            },
          },
        });
      });
    }
  }

  /**
   * Slider Interne
   */
  static interneSlider() {
    const $sliders = document.querySelectorAll(".sliderInterne");

    if ($sliders.length && !this.pluginMissing()) {
      const swiper = new Swiper(".sliderInterne .swiper-container", {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 700,
        // autoplay: {
        //        delay: 2500,
        //    },
        navigation: {
          nextEl: ".slider_navigation_next",
          prevEl: ".slider_navigation_prev",
        },
      });
    }
  }

  /**
   * Slider Tabs Horizontal
   */
  static tabsHorizontalSlider() {
    const $sliders = document.querySelectorAll(".tabs_slider");

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const swiper = new Swiper($slider.querySelector(".swiper-container"), {
          slidesPerView: 1,
          spaceBetween: 0,
          speed: 700,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".slider_navigation_next",
            prevEl: ".slider_navigation_prev",
          },
        });
      });
    }
  }

  /**
   * Slider Institut dans les medias
   */
  static mediasSlider() {
    const $sliders = document.querySelectorAll(".listing_medias");

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const swiper = new Swiper($slider.querySelector(".swiper-container"), {
          slidesPerView: 4,
          spaceBetween: 20,
          speed: 700,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".slider_navigation_next",
            prevEl: ".slider_navigation_prev",
          },
          breakpoints: {
            600: {
              slidesPerView: 1,
            },
            900: {
              slidesPerView: 2,
            },
            1100: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          },
        });
      });
    }
  }

  /**
   * Single institute slider
   */
  static singleInstituteSlider() {
    const $sliders = document.querySelectorAll(
      ".singleInstitute__hero__slider"
    );

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const slidesLength = $slider.querySelectorAll(".swiper-slide").length;
        const $navigations = {
          prevEl: $slider.querySelector(
            ".singleInstitute__hero__slider__navigationPrevious"
          ),
          nextEl: $slider.querySelector(
            ".singleInstitute__hero__slider__navigationNext"
          ),
        };

        new Swiper($slider.querySelector(".swiper-container"), {
          slidesPerView: 1,
          speed: 700,
          loop: false,
          navigation: $navigations,
          autoplay: {
            delay: 2500,
          },
          on: {
            slideChange: function () {
                updateSlideCounter(this, '.singleInstitute__hero__slider');
                updateProgress(this, '.singleInstitute__hero__slider');
            },
            init: function () {
                updateSlideCounter(this, '.singleInstitute__hero__slider');
                updateProgress(this, '.singleInstitute__hero__slider');
            },
            resize: function () {
                updateSlideCounter(this, '.singleInstitute__hero__slider');
                updateProgress(this, '.singleInstitute__hero__slider');
            },
         },
        });
      });

      function updateSlideCounter(swiper, selector) {
        swiper.snapGrid = [...swiper.slidesGrid];
        let totalSlides = swiper.slides.length;
        const currentSlide = swiper.activeIndex + 1;

        const slideCounter = document.querySelector(`${selector} .current-slide`);
        const totalCounter = document.querySelector(`${selector} .total-slides`);

        if (slideCounter) {
          slideCounter.innerText = currentSlide;
        }
        if (totalCounter) {
          totalCounter.innerText = totalSlides;
        }
      }

      function updateProgress(swiper, selector) {
        let totalSlides = swiper.slides.length;
        const currentIndex = swiper.activeIndex + 1;
        const progress = ((currentIndex / totalSlides) * 100) - 100;
        const progressBar = document.querySelector(`${selector} .js-progress-bar`);

        if (progressBar) {
          progressBar.style.strokeDashoffset = progress * -1;
        }
      }
    }
  }

  /**
   * Institute card
   */
  static instituteCard() {
    const $sliders = document.querySelectorAll(".instituteCard");

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const $imagesSlider = $slider.querySelector(
          ".instituteCard__image .swiper-container"
        );
        if ($imagesSlider) {
          const slidesLength =
            $imagesSlider.querySelectorAll(".swiper-slide").length;
          const $navigations = {
            prevEl: $slider.querySelector(
              ".instituteCard__image__navigationPrevious"
            ),
            nextEl: $slider.querySelector(
              ".instituteCard__image__navigationNext"
            ),
          };

          new Swiper($imagesSlider, {
            slidesPerView: 1,
            watchOverflow: true,
            navigation: $navigations,
          });
        }
      });
    }
  }

  /**
   * Download Ebook
   */
  static downloadEbook() {
      const $sliders = document.querySelectorAll(
        ".home .downloadEbook__ebooks"
      );

      if ($sliders.length && !this.pluginMissing()) {
        $sliders.forEach(($slider) => {
          const slidesLength = $slider.querySelectorAll(".swiper-slide").length;

          const $navigations = {
            prevEl: $slider.querySelector(
              ".downloadEbook__slider__navigationPrevious"
            ),
            nextEl: $slider.querySelector(
              ".downloadEbook__slider__navigationNext"
            ),
          };

          new Swiper($slider.querySelector(".swiper-container"), {
            slidesPerView: 1,
            watchOverflow: true,
            spaceBetween: 20,
            navigation: $navigations,
            loop: true,
            breakpoints: {
              767: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
              1400: {
                slidesPerView: 3,
              },
            },
            on: {
                slideChange: function () {
                    updateSlideCounter(this, '.downloadEbook');
                    updateProgress(this, '.downloadEbook');
                },
                init: function () {
                    updateSlideCounter(this, '.downloadEbook');
                    updateProgress(this, '.downloadEbook');
                },
                resize: function () {
                    updateSlideCounter(this, '.downloadEbook');
                    updateProgress(this, '.downloadEbook');
                },
            }
          });
        });
        
        function updateSlideCounter(swiper, selector) {
          let totalSlides = swiper.slides.length;
          const currentSlide = swiper.realIndex + 1;
          const slideCounter = document.querySelector(`${selector} .current-slide`);
          const totalCounter = document.querySelector(`${selector} .total-slides`);

          if (slideCounter) {
              slideCounter.innerText = currentSlide;
          }
          if (totalCounter) {
              if (totalSlides) {
                  totalCounter.innerText = totalSlides;
              } else {
                  totalCounter.innerText = 1;
              }
          }
        }

        function updateProgress(swiper, selector) {
          let totalSlides = swiper.slides.length;
          const currentIndex = swiper.realIndex + 1;

          const progress = ((currentIndex / totalSlides) * 100) - 100;
          const progressBar = document.querySelector(`${selector} .js-progress-bar`);

          if (progressBar) {
            progressBar.style.strokeDashoffset = progress * -1;
          }
        }
      }
  }


  /**
   * Also To Read Image
   */
  static alsoToReadImage() {
    const $sliders = document.querySelectorAll(".alsoToReadImage__slider");

    if ($sliders.length === 0) {
      return;
    }

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {

        new Swiper($slider.querySelector(".swiper-container"), {
          slidesPerView: 1,
          watchOverflow: true,
          spaceBetween: 30,
          rewind: true,
          navigation: {
            nextEl: ".alsoToReadImage__slider__navigationNext",
            prevEl: ".alsoToReadImage__slider__navigationPrevious",
          },
          breakpoints: {
            767: {
              slidesPerView: 2,
            },
          },
          on: {
            slideChange: function () {
              updateSlideCounter(this, '.alsoToReadImage__slider__navigations');
              updateProgress(this, '.alsoToReadImage__slider__navigations');
            },
            init: function () {
              updateSlideCounter(this, '.alsoToReadImage__slider__navigations');
              updateProgress(this, '.alsoToReadImage__slider__navigations');
            },
            resize: function () {
              updateSlideCounter(this, '.alsoToReadImage__slider__navigations');
              updateProgress(this, '.alsoToReadImage__slider__navigations');
            },
          }
        });
      });
    }

    function updateSlideCounter(swiper, selector) {
      let totalSlides = swiper.slides.length;
      const currentSlide = swiper.activeIndex + 1;
      const slidesPerView = swiper.slidesPerViewDynamic() - 1;
      const slideCounter = document.querySelector(`${selector} .current-slide`);
      const totalCounter = document.querySelector(`${selector} .total-slides`);

      if (slideCounter) {
          slideCounter.innerText = currentSlide;
      }
      if (totalCounter) {
          if ((totalSlides - slidesPerView) > 0) {
              totalCounter.innerText = totalSlides - slidesPerView;
          } else {
              totalCounter.innerText = 1;
          }
      }
    }

    function updateProgress(swiper, selector) {
      const slidesPerView = swiper.slidesPerViewDynamic() - 1;
      let totalSlides = 1;
      if ((swiper.slides.length - slidesPerView) > 0) {
          totalSlides = swiper.slides.length - slidesPerView;
      }
      const currentIndex = swiper.activeIndex + 1;
      const progress = ((currentIndex / totalSlides) * 100) - 100;
      const progressBar = document.querySelector(`${selector} .js-progress-bar`);

      if (progressBar) {
          progressBar.style.strokeDashoffset = progress * -1;
      }
    }
  }

  /**
   * Download Ebook
   */
  static sidebardownloadEbook() {
    const $sliders = document.querySelectorAll(
      ".page-template-deep-page .sidebarslide, .downloadEbook__ebooks.sidebarslide"
    );

    if ($sliders.length && !this.pluginMissing()) {
      $sliders.forEach(($slider) => {
        const slidesLength = $slider.querySelectorAll(".swiper-slide").length;

        const $navigations = {
          prevEl: $slider.querySelector(
            ".downloadEbook__slider__navigationPrevious"
          ),
          nextEl: $slider.querySelector(
            ".downloadEbook__slider__navigationNext"
          ),
        };

        new Swiper($slider.querySelector(".swiper-container"), {
          slidesPerView: 1,
          watchOverflow: true,
          spaceBetween: 10,
          navigation: $navigations,
          pagination: {
            el: '.downloadEbook__ebooks__pagination',
            clickable: true
          },
          on: {
            slideChange: function () {
                updateSlideCounter(this, '.sidebarslide');
                updateProgress(this, '.sidebarslide');
            },
            init: function () {
                updateSlideCounter(this, '.sidebarslide');
                updateProgress(this, '.sidebarslide');
            },
            resize: function () {
                updateSlideCounter(this, '.sidebarslide');
                updateProgress(this, '.sidebarslide');
            },
          }
          
        });
      });

      function updateSlideCounter(swiper, selector) {
        let totalSlides = swiper.slides.length;
        const currentSlide = swiper.activeIndex + 1;
        const slidesPerView = swiper.slidesPerViewDynamic() - 1;
        const slideCounter = document.querySelector(`${selector} .current-slide`);
        const totalCounter = document.querySelector(`${selector} .total-slides`);

        if (slideCounter) {
            slideCounter.innerText = currentSlide;
        }
        if (totalCounter) {
            if ((totalSlides - slidesPerView) > 0) {
                totalCounter.innerText = totalSlides - slidesPerView;
            } else {
                totalCounter.innerText = 1;
            }
        }
    }

    function updateProgress(swiper, selector) {
        const slidesPerView = swiper.slidesPerViewDynamic() - 1;
        let totalSlides = 1;
        if ((swiper.slides.length - slidesPerView) > 0) {
            totalSlides = swiper.slides.length - slidesPerView;
        }
        const currentIndex = swiper.activeIndex + 1;
        const progress = ((currentIndex / totalSlides) * 100) - 100;
        const progressBar = document.querySelector(`${selector} .js-progress-bar`);

        if (progressBar) {
            progressBar.style.strokeDashoffset = progress * -1;
        }
    }
    }
  }

  /**
   * Init all sliders,
   *
   * throw an error if Swiper isn't defined
   */
  static initSliders() {
    this.defaultSlider();
    this.institutSlider();
    // this.listBlocksSlider();
    this.soinsSlider();
    this.instituteCard();
    this.accompagnerSlider();
    this.interneSlider();
    this.tabsHorizontalSlider();
    this.mediasSlider();
    this.singleInstituteSlider();
    this.textMediaSlider();
    this.blockList();
    this.institutesSlider();
    this.instituteCards();
    this.homeInformations();
    this.downloadEbook();
    this.sidebardownloadEbook();
    this.alsoToReadImage();
  }
}
