export default class TrackingEvent {

    /**
     * @param {Array} $selector 
     * @param {Array} $data 
     */
	static Click($selector, $data) {
		const $elements = document.querySelectorAll($selector);

		if ($elements) {
			$elements.forEach((el) => {
				el.addEventListener("click", (e) => {
					dataLayer.push($data);
				});
			});
		}
	}

}