export default class MapsInstitutes {
    static init() {
        const elements = document.querySelectorAll('.institutesMapsWithSearch__institute');
        const allCenters = document.querySelector('.institutesMapsWithSearch__instituts');

        if (!elements) {
            return;
        }

        elements.forEach(el => {
            el.addEventListener('mouseenter', () => {
                if (el.hasAttribute('data-centers')) {
                    const centersIDS = el.getAttribute('data-centers');
                    document.dispatchEvent(new CustomEvent('areaClicked', { detail: centersIDS }));
                }
            });

            el.addEventListener('mouseleave', () => {
                if (allCenters.hasAttribute('all-centers')) {
                    document.dispatchEvent(new CustomEvent('noCenterHovered', { detail: allCenters.getAttribute('all-centers') }));
                }
            });
        });

        document.addEventListener('markerClicked', function (event) {
            const centerID = event.detail;

            elements.forEach(element => {
                if (element.getAttribute('center-id') == centerID) {
                    element.classList.add('active');
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                } else {
                    element.classList.remove('active');
                }
            });
        });
    }
}