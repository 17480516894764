import {$} from '../helpers';
import '../vendors/TweenMax';

/**
 * Cursor base
 */
const cursor = {

    cursor: '#custom-cursor__wrapper',
    isInit: false,

    move: function () {
        let $element = $(this.cursor);

        $(document).on('mousemove', function (e) {
           TweenLite.to($element, 0, {
               x: e.clientX - $element.width() / 2,
               y: e.clientY - $element.height() / 2
           })
        });
    },

    init: function () {
        if (this.isInit) return;

        this.move();
        this.isInit = true;
    }

};

/**
 * Draggable cursor
 */
const draggableCursor = {

    cursor: '#custom-cursor__draggable',
    cursorIconProperties: {},
    showElement: '.sliderSoins__slider',

    /**
     * Show this cursor if
     */
    show: function () {
        return !!$(this.showElement).length;
    },

    /**
     * Get default width and height of the cursor icon
     */
    getDefaultCursorIconProperties: function () {
      let $cursorIcon = $(this.cursor).find('.custom-cursor__draggable__cursor__icon');

      this.cursorIconProperties = {
          width: $cursorIcon.removeAttr('style').outerWidth(),
          height: $cursorIcon.removeAttr('style').outerHeight()
      }
    },

    /**
     * Animation on mouse press
     */
    toggleAnimation: function (active = true) {
        let $element = $(this.cursor),
            cursorIconProperties = this.cursorIconProperties,
            $cursorIcon = $element.find('.custom-cursor__draggable__cursor__icon'),
            $cursorLeftArrow = $element.find('.custom-cursor__draggable__arrow-left'),
            $cursorRightArrow = $element.find('.custom-cursor__draggable__arrow-right');

        if (active) {
            $(document).on('mousedown.draggableCursor', function (e) {
                if (e.which === 1) { // Left click
                    TweenLite.to($cursorIcon, 0.3, {
                        width: cursorIconProperties.width / 1.5 + 'px',
                        height: cursorIconProperties.height / 1.5 + 'px'
                    });
                    TweenLite.to($cursorLeftArrow, 0.2, {
                        x: (cursorIconProperties.width / 4) * -1
                    });
                    TweenLite.to($cursorRightArrow, 0.2, {
                        x: cursorIconProperties.width / 4
                    });
                }
            });

            $(document).on('mouseup.draggableCursor', function (e) {
                if (e.which === 1) { // Left click
                    TweenLite.to($cursorIcon, 0.3, {
                        width: cursorIconProperties.width + 'px',
                        height: cursorIconProperties.height + 'px',
                        clearProps: 'all'
                    });
                    TweenLite.to($cursorLeftArrow, 0.2, {
                        x: 0,
                        clearProps: 'all'
                    });
                    TweenLite.to($cursorRightArrow, 0.2, {
                        x: 0,
                        clearProps: 'all'
                    });
                }
            });
        } else {
            $(document).off('mousedown.draggableCursor');
            $(document).off('mouseup.draggableCursor');
        }
    },

    /**
     * Show the cursor
     */
    showOn: function () {
        let $element = $(this.cursor);

        $(this.showElement).on('mouseenter', () => {
            $element.css('opacity', 1);
            this.toggleAnimation();
        });

        $(this.showElement).on('mouseleave', () => {
            let $cursorIcon = $element.find('.custom-cursor__draggable__cursor__icon');

            $element.css('opacity', 0);
            this.toggleAnimation(false);
            $cursorIcon.removeAttr('style');
        });
    },

    init: function () {
        cursor.init();
        this.getDefaultCursorIconProperties();
        this.showOn();
    }

};

/**
 * Init cursors
 */
export const initCursors = () => {
    if (draggableCursor.show()) draggableCursor.init();
};