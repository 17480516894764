'use strict';

import smoothScrollPolyfill from './vendors/smoothscroll-polyfill';
import OutlineManager from './components/OutlineManager';
import DOMAnimations from "./components/DOMAnimations";
import GumShoe from './vendors/GumShoe';
import { addMultipleEventListener, calcOffset, debounce, getFocusableElements, setHeaderHeight } from './helpers';
import Sliders from './Sliders';
import './custom-elements/GoogleMaps';
import MaterialInput from './components/MaterialInput';
import InteractiveAreasModule from './components/InteractiveAreasModule';
import Tabs from './components/Tabs';
import LineMove from './components/LineMove';
import hoverintent from './vendors/hoverintent';
import BackToTop from './custom-elements/BackToTop';
import Modal from './custom-elements/Modal';
import Accordion from './custom-elements/Accordion/Accordion';
import DataSEO from './components/DataSEO';
import CardSlide from './components/CardSlide';
import Popup from './components/Popup';
import InteractivePointsModule from './components/InteractivePointsModule';
import KamiFlash from './vendors/KamiFlash.umd';
import Tracking from './components/Tracking';
import SliderWithFilter from './components/SliderWithFilter';
import Anchors from './components/Anchors';
import MapsInstitutes from './components/MapsInstitutes';

class App {

	constructor(settings = {}) {
		smoothScrollPolyfill.polyfill();
		this.settings = {};
		KamiFlash.initialPosition = 350;
		this.setupSettings(settings);
		this.cacheDOM();
		this.init();
	}

	/**
	 * Set up settings defined in the creation of the instance
	 *
	 * @param settings
	 */
	setupSettings(settings) {
		if (Object.keys(settings).length > 0) {
			for (let key in settings) {
				if (settings.hasOwnProperty(key) && this.settings.hasOwnProperty(key)) this.settings[key] = settings[key];
			}
		}
	}

	/**
	 * Cache DOM elements
	 */
	cacheDOM() {
		this.$backToTop = document.querySelector('back-to-top');
		this.$searchBar = document.querySelector('.header__searchBar');
	}

	/**
	 * Initialization of all custom elements
	 */
	initCustomElements() {
		customElements.define('nav-tabs', Tabs);
		customElements.define('back-to-top', BackToTop);
		customElements.define('modal-popup', Modal);
		customElements.define('nav-accordion', Accordion);
		customElements.define('flash-message', KamiFlash);
	}


	/**
	 * Initialization of all modules
	 */
	initModules() {
		const $gtmDataEvents = document.querySelectorAll('[data-gtm-event]');

		if ($gtmDataEvents.length > 0) {
			$gtmDataEvents.forEach($gtmDataEvent => {
				$gtmDataEvent.addEventListener('click', (e) => {
					const gtmEvent = $gtmDataEvent.dataset.gtmEvent;
					const gtmAttributes = JSON.parse($gtmDataEvent.dataset.gtmAttributes);
					if (window.dataLayer) {
						window.dataLayer.push({
							...{
								'event': gtmEvent
							}, ...gtmAttributes
						});
					}
				});
			});
		}

		this.contactFormsChoices();
		MaterialInput.init();
		(
			function ($) {
				var clickDelay = 500,
					clickDelayTimer = null;

			}
		)(jQuery);

		this.handleVideos();

		// Accordion
		this.accordionBlocks();

		// All Slider
		Sliders.initSliders();

		Popup.init();

		this.validateForm();

		this.inputFocused();

		this.learnMoreStoreLocator();

		// anchorBlock
		this.anchorBlock();

		this.responsiveHeader();

		this.headerMenu();

		this.menuActiveItems();

		// Phone contacts
		this.phoneContacts();

		this.phonePopup();

		this._newsletterPopup();

		this._buttonDownloadBook();

		this.responsiveInteractivePointsModule();

		this.handleSearchBar();
		this._submiturlchange();

		this.downloadEbook();

		this.popupEbook();

		this._popupPdf();

		this.validateFormEbook();

		this._popupPostuler();
		this.institutesPopupWithMaps();

		this._copyLink();
		this._checkQueriesAndDispatchEvent(['.page-template-institutes-page', '.single-institute', '.page-template-contact-page']);

		this.__textJobSameHeight();
		this.popupEbookResume();

		Tracking.init();
		SliderWithFilter.init();
		Anchors.init();
		MapsInstitutes.init();

		new LineMove({
			element: '.headerLine',
			hover: '.appMenu__firstLevel > li',
			activeSelector: '.appMenu__firstLevel > li.appMenu__menuItem--isActive',
			relativeTo: '#header-site'
		});

		if (document.querySelector('.appMenu__firstLevel > li.appMenu__isActivePage')) {
			document.body.classList.add('appMenu__firstLevel--hasActiveItem');
		}

		const $interactiveAreasModule = document.querySelector('.interactiveModule__content--areas');
		if ($interactiveAreasModule) {
			new InteractiveAreasModule($interactiveAreasModule);
		}

		const interactivePointsModule = document.querySelector('.interactiveModule__content--points');
		if (interactivePointsModule) {
			new InteractivePointsModule(interactivePointsModule);
		}

		// Placeholder for module AutoSearch
		const $autoSearchInput = document.querySelector('.wp_autosearch_input');
		// if ($autoSearchInput) $autoSearchInput.setAttribute('placeholder', 'Recherchez un centre, une pathologie, un traitement, ....');
		this.initAnchorBlock();

		const $homeInformationsAccordion = document.querySelector('.homeInformations__accordion');
		if ($homeInformationsAccordion) {
			const closeAccordion = ($accordion) => {
				DOMAnimations.slideUp($accordion.querySelector('[data-accordion-item-section]'));
			};
			const openAccordion = ($accordion) => {
				DOMAnimations.slideDown($accordion.querySelector('[data-accordion-item-section]'));
			};
			openAccordion($homeInformationsAccordion.querySelector('nav-accordion-item[expanded="true"]'));
			[...$homeInformationsAccordion.querySelectorAll('nav-accordion-item')].forEach($accordionItem => {
				$accordionItem.addEventListener(
					'isExpanded',
					(e) => {
						if (e.detail.expanded) {
							openAccordion($accordionItem);
						}
					}
				);
				$accordionItem.addEventListener(
					'isNotExpanded',
					(e) => {
						if (!e.detail.expanded) {
							closeAccordion($accordionItem);
						}
					}
				);
			});
		}

		// Send informations to GTM about finding our centers
		const $searchOurCenter = document.querySelector('#store_locator_find_stores_button');
		if (window.dataLayer && $searchOurCenter) {
			$searchOurCenter.addEventListener('click', () => {
				window.dataLayer.push({
					'event': 'search_center_click',
					'city': document.querySelector('#store_locator_address_field').value || null,
					'radius': document.querySelector('#store_locator_filter_radius').value || null
				});
			});
		}

		if (this.$backToTop && this.$searchBar) {
			this.$backToTop.addEventListener('backToTopScrollStart', () => this.$searchBar.style.display = 'none');
			this.$backToTop.addEventListener('backToTopScrollEnd', () => this.$searchBar.style.display = '');
		}

		const dataSeo = new DataSEO();
		dataSeo.init();

		const slideDownCards = document.querySelectorAll('.instituteCard');
		if (slideDownCards.length > 0 && slideDownCards[0].offsetHeight > 870) {
			[...slideDownCards].forEach(card => {
				const cardButton = card.querySelector('.instituteCardExtend');
				const cardContent = card.querySelector('.instituteCard__content');
				cardButton.style.display = 'block';
				cardContent.classList.add('reduced');

				cardButton.addEventListener(
					'click',
					() => {
						[...slideDownCards].forEach(onecard => {
							const button = onecard.querySelector('.instituteCardExtend');
							const content = onecard.querySelector('.instituteCard__content');
							button.classList.toggle('augmented');
							button.classList.contains('augmented') ? CardSlide.cardSlideDown(content) : CardSlide.cardSlideUp(content);
							content.classList.toggle('reduced');
						});
					}
				);
			});
		}

		this._centerPageButtons();

		const popup = document.querySelector('.popup__teleconsultation');
		const popupCross = document.querySelector('.popup__teleconsultation__cross');
		const popupTitle = document.querySelector('.popup__teleconsultation__title');

		if (popup) {
			popupTitle.addEventListener('click', () => {
				popup.classList.add('isShow')
			})
			popupCross.addEventListener('click', () => {
				popup.classList.remove('isShow')
			})
		}
	}

	/**
	* Copy link
	*/
	_copyLink() {
		const copyButton = document.getElementById('copyLinkButton');
		const postLink = window.location.href;

		if (!copyButton) return;

		copyButton.addEventListener('click', function () {
			const tempInput = document.createElement('input');
			tempInput.value = postLink;
			document.body.appendChild(tempInput);
			tempInput.select();
			document.execCommand('copy');
			document.body.removeChild(tempInput);
		});
	}

	/**
	 * Post
	 *
	 * @private
	 */
	__textJobSameHeight() {
		const boxes = document.querySelectorAll('.specialist__job');

		if (!boxes) {
			return;
		}

		if (window.innerWidth > 640) {
			let maxHeight = 0;
			boxes.forEach(box => {
				maxHeight = Math.max(maxHeight, box.offsetHeight);
			});

			function findMaxHeight() {
				let maxHeight = 0;
				boxes.forEach(box => {
					const boxHeight = box.offsetHeight;
					maxHeight = Math.max(maxHeight, boxHeight);
				});
				return maxHeight;
			}

			const maxDivHeight = findMaxHeight();

			boxes.forEach(box => {
				box.style.height = `${maxDivHeight}px`;
			});
		}
	}

	/**
	  * popup Appointement
	*/
	_submiturlchange() {

		const mySelect = document.getElementById("popup-select");
		const mySelect2 = document.getElementById("popup-select-2");
		const myLink = document.getElementById("popup-link");
		const myLink2 = document.getElementById("popup-link-2");

		if (!mySelect || !mySelect2 || !myLink || !myLink2) {
			return;
		}

		const firstSelect = mySelect.options[mySelect.selectedIndex];
		myLink.href = firstSelect.value;

		const textFirstSelect = mySelect.options[mySelect.selectedIndex].text;
		const firstDataseoAttribut = textFirstSelect.replace(/\s+/g, '-');
		myLink.setAttribute('data-seo-doctolib', firstDataseoAttribut);

		mySelect.addEventListener('change', () => {
			const selectedOption = mySelect.options[mySelect.selectedIndex];
			const textSelect = mySelect.options[mySelect.selectedIndex].text;
			const dataseoAttribut = textSelect.replace(/\s+/g, '-');

			myLink.href = selectedOption.value;
			myLink.setAttribute('data-seo-doctolib', dataseoAttribut);
		});

		mySelect2.addEventListener('change', () => {
			const selectedOption = mySelect2.options[mySelect2.selectedIndex];
			const textSelect = mySelect2.options[mySelect2.selectedIndex].text;
			const dataseoAttribut = textSelect.replace(/\s+/g, '-');

			myLink2.href = selectedOption.value;
			myLink2.setAttribute('data-seo-phone', dataseoAttribut);
		});
	}


	/**
	* popup PDF
	*/
	_popupPdf() {
		function setCookie(cname, cvalue, exdays) {
			var d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			var expires = "expires=" + d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		}

		setTimeout(() => {
			const popup = document.querySelector('.popup-pdf');
			const overlay = document.querySelector('.popup-pdf-overlay');

			if (!popup || !overlay) return;

			const popupClosedRecently = document.cookie.includes('popupClosed=true');
			if (!popupClosedRecently) {
				// Show the popup if it was not closed recently
				popup.classList.remove('hide');
				overlay.classList.remove('hide');
			}

			const closeBtn = document.querySelector('.popup-pdf .close');
			if (closeBtn) {
				closeBtn.addEventListener('click', () => {
					popup.classList.add('hide');
					overlay.classList.add('hide');
					setCookie("popupClosed", "true", 1);
				});
			}

			if (overlay) {
				overlay.addEventListener('click', () => {
					popup.classList.add('hide');
					overlay.classList.add('hide');
				});
			}
		}, 3000);
	}

	responsiveHeader() {
		const RESPONSIVE_HEADER_ACTIVE_CLASS = 'appMenuIsActive';
		const $hamburger = document.querySelector('.header_right_bottom_hamburger');
		const $appMenu = document.querySelector('.appMenu');

		const responsiveMenuIsOpen = () => document.body.classList.contains(RESPONSIVE_HEADER_ACTIVE_CLASS);

		$hamburger.addEventListener('click', e => {
			e.preventDefault;
			if (responsiveMenuIsOpen()) {
				$hamburger.classList.remove('is-active');
				document.body.classList.remove(RESPONSIVE_HEADER_ACTIVE_CLASS);
			} else {
				$hamburger.classList.add('is-active');
				document.body.classList.add(RESPONSIVE_HEADER_ACTIVE_CLASS);
			}
		});

		// Backdrop click
		$appMenu.addEventListener('mousedown', e => {
			if (e.target !== $appMenu) return;

			$hamburger.classList.remove('is-active');
			document.body.classList.remove(RESPONSIVE_HEADER_ACTIVE_CLASS);
		});

		// Back to previous menu
		[...document.querySelectorAll('.appMenu__subMenu__title')].forEach($appMenuSubMenuTitle => {
			$appMenuSubMenuTitle.addEventListener('click', e => {
				e.preventDefault();
				const $closestActiveMenu = $appMenuSubMenuTitle.closest('.appMenu__isActive');
				if ($closestActiveMenu) {
					$closestActiveMenu.classList.remove('appMenu__isActive');
				}
				return false;
			});
		});

		// First levels
		[
			...document.querySelectorAll('.appMenu__basicSubMenu > li'),
			...document.querySelectorAll('.appMenu__firstLevel > li')
		].forEach($firstLevelLi => {
			const $button = $firstLevelLi.querySelector('button');
			if ($button) {
				$button.addEventListener('click', e => {
					e.preventDefault();
					$firstLevelLi.classList.toggle('appMenu__isActive');
					return false;
				});
			}
		});

		// Other levels

		const $menuParent = document.querySelectorAll('.appMenu__subMenuColumnsWithPictos > li');
		const $menuChild = document.querySelectorAll('[data-sub-menu-type="sub_menu_items"] > ul > li');

		$menuParent.forEach($menuItem => {
			const $button = $menuItem.querySelector('button');

			if ($button) {
				$button.addEventListener('click', e => {
					e.preventDefault();

					document.querySelectorAll('.appMenu__subMenuColumnsWithPictos > li').forEach(item => {
						if (item !== $menuItem && item.classList.contains('appMenu__isActive')) {
							item.classList.remove('appMenu__isActive');
							DOMAnimations.slideToggle(item.querySelector('ul')); // Slide up other menus
						}
					});

					$menuItem.classList.toggle('appMenu__isActive');
					DOMAnimations.slideToggle($menuItem.querySelector('ul'));
					return false;
				});
			}
		});

		$menuChild.forEach($menuChildItem => {
			const $buttonChild = $menuChildItem.querySelector('button');

			if ($buttonChild) {
				$buttonChild.addEventListener('click', e => {
					e.preventDefault();

					document.querySelectorAll('[data-sub-menu-type="sub_menu_items"] > ul > li').forEach(item => {
						if (item !== $menuChildItem && item.classList.contains('appMenu__isActive')) {
							item.classList.remove('appMenu__isActive');
							DOMAnimations.slideToggle(item.querySelector('ul'));
						}
					});

					$menuChildItem.classList.toggle('appMenu__isActive');
					DOMAnimations.slideToggle($menuChildItem.querySelector('ul'));
					return false;
				});
			}
		});
	}

	/*inputFocused */
	inputFocused() {
		var textareas = document.querySelectorAll('.input-control textarea');

		function handleFocus() {
			if (!this.classList.contains('input--focused')) {
				this.classList.add('input--focused');
				this.parentNode.classList.add('input--active');
			}
		}

		function handleBlur() {
			if (this.value === '' && this.classList.contains('input--focused')) {
				this.classList.remove('input--focused');
				this.parentNode.classList.remove('input--active');
			}
		}

		for (var i = 0; i < textareas.length; i++) {
			textareas[i].addEventListener('focus', handleFocus);
			textareas[i].addEventListener('blur', handleBlur);
		}
	}

	/**
	 * validateForm
	 */


	validateForm() {
		const form = document.querySelector('#comment-form');
		if (!form) {
			return;
		}

		const nameEL = document.querySelector('#author');
		const commentEL = document.querySelector('#comment');
		const emailEL = form.querySelector('#email');
		const isRequired = value => value === '' ? false : true;
		const isEmailValid = (email) => {
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		};
		const showError = (formField) => {
			formField.parentElement.classList.remove('success');
			formField.parentElement.classList.add('error');
		};
		const showSuccess = (formField) => {
			formField.parentElement.classList.remove('error');
			formField.parentElement.classList.add('success');
		};

		const checkEmail = () => {
			let valid = false;
			const email = emailEL.value.trim();
			if (!isEmailValid(email)) {
				showError(emailEL)
			} else {
				showSuccess(emailEL);
				valid = true;
			}
			return valid;
		};

		const checkUsername = () => {
			let valid = false;
			const name = nameEL.value.trim();

			if (!isRequired(name)) {
				showError(nameEL);
			} else {
				showSuccess(nameEL);
				valid = true;
			}
			return valid;
		}

		const checkComment = () => {
			let valid = false;
			const comment = commentEL.value.trim();

			if (!isRequired(comment)) {
				showError(commentEL);
			} else {
				showSuccess(commentEL);
				valid = true;
			}
			return valid;
		}

		form.setAttribute('novalidate', true);

		form.addEventListener("submit", (event) => {
			event.preventDefault();

			let isUsernameValid = checkUsername(),
				isEmailValid = checkEmail(),
				isCommentValid = checkComment();

			let isFormValid = isUsernameValid &&
				isEmailValid &&
				isCommentValid;

			if (isFormValid) {
				form.submit();
			}
		});
	}

	/**
	 * Handle header menu
	 */
	headerMenu() {
		const $firstLevelItems = document.querySelectorAll('.appMenu__firstLevel > li');
		if (!$firstLevelItems) return;

		$firstLevelItems.forEach($firstLevelItem => {
			const $subMenu = $firstLevelItem.querySelector('.appMenu__subMenu');

			if ($subMenu) {
				hoverintent(
					$firstLevelItem,
					() => {
						if (this.isResponsive()) return;
						$firstLevelItem.classList.add('appMenu__isActive');
					},
					() => {
						if (this.isResponsive()) return;
						$firstLevelItem.classList.remove('appMenu__isActive');
					}
				).options({
					timeout: 100
				});
			}
		});

		this.basicSubMenu();
		this.columnsWithPictosSubMenu();
	}

	columnsWithPictosSubMenu() {
		const $columnsWithPictosSubMenus = document.querySelectorAll('.appMenu__subMenuColumnsWithPictos');
		if (!$columnsWithPictosSubMenus) return;

		const COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS = 'appMenu__isHovered';

		$columnsWithPictosSubMenus.forEach($columnWithPictosSubMenu => {
			// Activate first menu
			const $firstSubMenu = $columnWithPictosSubMenu.querySelector('.appMenu__subMenuColumnsWithPictos__subMenu');
			if ($firstSubMenu) {
				$firstSubMenu.parentNode.classList.add(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS);
				const $lastColumn = $firstSubMenu.querySelector('.appMenu__subMenuColumnsWithPictos__subMenu__last');
				if ($lastColumn) {
					$lastColumn.parentNode.classList.add(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS);
				}
			}

			// Persist hover state on first column items
			const $firstColumnItems = $columnWithPictosSubMenu.children;
			if ($firstColumnItems) {
				[...$firstColumnItems].forEach($firstColumnItem => {
					const that = this;
					$firstColumnItem.addEventListener('mouseenter', function () {
						if (that.isResponsive()) return;
						const $hoveredItems = $columnWithPictosSubMenu.querySelectorAll(`.${COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS}`);
						if ($hoveredItems) {
							[...$hoveredItems].forEach($hoveredItem => $hoveredItem.classList.remove(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS));
						}
						this.classList.add(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS);

						// Activate hover state on the first sub menu if there is one
						const $lastColumn = this.querySelector('.appMenu__subMenuColumnsWithPictos__subMenu__last');
						if ($lastColumn) {
							$lastColumn.parentNode.classList.add(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS);
						}
					});
				});
			}

			// Persist hover state on sub menu (second column items)
			const $secondColumnItems = $columnWithPictosSubMenu.querySelectorAll('[data-sub-menu-type="sub_menu_items"]');
			if ($secondColumnItems) {
				[...$secondColumnItems].forEach($secondColumnItem => {
					const $secondColumnMenuItems = $secondColumnItem.children[0].children;
					if ($secondColumnMenuItems) {
						[...$secondColumnMenuItems].forEach($secondColumnMenuItem => {
							const that = this;
							$secondColumnMenuItem.addEventListener('mouseenter', function () {
								if (that.isResponsive()) return;
								const $hoveredItems = $secondColumnItem.querySelectorAll(`.${COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS}`);
								if ($hoveredItems) {
									[...$hoveredItems].forEach($hoveredItem => $hoveredItem.classList.remove(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS));
								}
								this.classList.add(COLUMN_WITH_PICTOS_SUB_MENU_HOVER_CLASS);
							});
						});
					}
				});
			}
		});
	}

	/**
	 * Download Ebook
	 */
	downloadEbook() {
		const downloadButton = document.getElementById('downloadButton');
		const fileSelect = document.getElementById('file');

		if (!downloadButton) {
			return false;
		}

		downloadButton.addEventListener('click', function () {
			const selectedFile = fileSelect.value;

			const downloadLink = document.createElement('a');
			downloadLink.href = selectedFile;
			downloadLink.target = '_blank';
			downloadLink.download = selectedFile;

			downloadLink.click();
		});
	}

	/**
	 * Popup Ebook
	 */
	popupEbook() {
		const openButtons = document.querySelectorAll('.downloadEbook__ebook__button a');
		const popup = document.querySelector('.popup__ebook');
		const closeButton = document.getElementById('closePopup');
		const overlay = document.querySelector('.popup__ebook__overlay');

		if (!closeButton || !overlay) {
			return false;
		}

		function openPopup() {
			popup.style.display = 'block';
			overlay.style.display = 'block';
		}

		function closePopup() {
			popup.style.display = 'none';
			overlay.style.display = 'none';
		}

		openButtons.forEach((button) => {
			button.addEventListener('click', openPopup);
		});

		closeButton.addEventListener('click', closePopup);

		overlay.addEventListener('click', closePopup);
	}

	/**
	 * Popup Ebook
	 */
	popupEbookResume() {
		const openButtons = document.querySelectorAll('.downloadEbook__ebook__read a');
		const popup = document.querySelector('.popup__ebook-resume');
		const closeButton = document.getElementById('close-popup-ebook-resume');
		const overlay = document.querySelector('.popup__ebook-resume__overlay');

		if (!closeButton || !overlay || !popup || !openButtons) {
			return false;
		}

		function openPopup() {
			popup.style.display = 'block';
			overlay.style.display = 'block';
		}

		function closePopup() {
			popup.style.display = 'none';
			overlay.style.display = 'none';
		}

		// Ensure pdf.js worker is loaded
		pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';

		// Function to load and render full PDF from URL into a container
		function loadPdfFromUrl(url, container) {
			pdfjsLib.getDocument(url).promise.then((pdfDoc) => {
				container.innerHTML = ''; // Clear previous content

				// Render all pages
				for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
					pdfDoc.getPage(pageNum).then((page) => {
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						container.appendChild(canvas);

						// Adjust scale dynamically based on screen width
						const viewport = page.getViewport({ scale: 1 });
						const scale = Math.min(window.innerWidth / viewport.width, 1.5);
						const scaledViewport = page.getViewport({ scale });

						canvas.height = scaledViewport.height;
						canvas.width = scaledViewport.width;

						const renderContext = {
							canvasContext: ctx,
							viewport: scaledViewport
						};

						page.render(renderContext);
					});
				}
			}).catch((error) => {
				console.error('Error loading PDF:', error);
			});
		}

		const pdfContainer = document.getElementById('pdf-container');
		openButtons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				let pdfUrl = button.getAttribute('data-pdf');
				// Complete here: Load the PDF into the popup container
				if (pdfContainer) {
					loadPdfFromUrl(pdfUrl, pdfContainer);
				} else {
					console.error('PDF container not found!');
				}

				openPopup();
			});
		});

		closeButton.addEventListener('click', closePopup);

		overlay.addEventListener('click', closePopup);
	}

	/**
	 * Validate Ebook
	 */
	validateFormEbook() {
		document.addEventListener('input', function () {
			const nomInput = document.querySelector('input[name="NOM"]');
			const emailInput = document.querySelector('input[name="EMAIL"]');
			const fileSelect = document.getElementById('file');
			const button = document.getElementById('downloadButton');

			if (!nomInput || !emailInput || !fileSelect || !button) return;

			const allFilled = nomInput.value.trim() !== '' && emailInput.value.trim() !== '' && fileSelect.value !== '';

			button.disabled = !allFilled;
		});
	}



	/**
	 * Handle basic sub menu
	 */
	basicSubMenu() {
		const $basicSubMenus = document.querySelectorAll('.appMenu__basicSubMenu');
		if (!$basicSubMenus) return;

		let oldTimestamp = null;

		$basicSubMenus.forEach($basicSubMenu => {
			const $subMenuTogglers = $basicSubMenu.querySelectorAll('.appMenu__subMenuToggler');
			if ($subMenuTogglers) {
				$subMenuTogglers.forEach($subMenuToggler => {
					const $parent = $subMenuToggler.parentNode.parentNode;
					hoverintent(
						$parent,
						() => {
							if (this.isResponsive()) return;
							$parent.classList.add('appMenu__isActive');
						},
						() => {
							if (this.isResponsive()) return;
							$parent.classList.remove('appMenu__isActive');
						}
					).options({
						timeout: 100
					});

					$subMenuToggler.addEventListener('click', e => {
						if (!this.isResponsive()) return;
						if (oldTimestamp === null || oldTimestamp + 500 < e.timeStamp) {
							e.preventDefault();
							$parent.classList.toggle('isActive');
							DOMAnimations.slideToggle($parent.querySelector('.appMenu__basicSubMenu__subMenu'));
							oldTimestamp = e.timeStamp;
							return false;
						}
					});
				});
			}
		});
	}

	/**
	 * Phone popup
	 */
	phonePopup() {
		const $phones = document.querySelectorAll('[data-phones-button]');
		const $popupPhone = document.querySelector('.popupPhone');
		const $popupPhoneOverlay = document.querySelector('.popupPhone-overlay');

		if (!$phones || !$popupPhone) return;

		const $CloseButton = $popupPhone.querySelector('button.close');

		// Open the popup
		const openPopupPhone = () => {
			$popupPhone.classList.add('show');
			$popupPhoneOverlay.style.opacity = '1';
		};

		// Close the popup
		const closePopupPhone = () => {
			$popupPhone.classList.remove('show');
			$popupPhoneOverlay.style.opacity = '0';
		};

		// On close button click
		$CloseButton.addEventListener('click', e => {
			e.preventDefault();
			closePopupPhone();
			return false;
		});

		document.addEventListener('click', e => {
			const clickedOutsidePopup = !$popupPhone.contains(e.target);
			const clickedOnPhoneButton = Array.from($phones).some($phone => $phone.contains(e.target));

			if (clickedOutsidePopup && !clickedOnPhoneButton) {
				closePopupPhone();
			}
		});

		$phones.forEach($phone => {
			$phone.addEventListener('click', e => {
				e.preventDefault();
				openPopupPhone();
				return false;
			});
		});
	}

	/**
	 * Handle phone contacts
	 */
	phoneContacts() {
		const $phoneContactsTogglers = document.querySelectorAll('.modal-popup-rdv');
		const $phoneContactsPopup = document.querySelector('.phoneContacts.popup');
		if (!$phoneContactsTogglers || !$phoneContactsPopup) return;

		const PHONE_CONTACTS_ACTIVE_CLASS = 'phoneContactsIsActive'; // Body class to trigger the popup
		const $phoneContactsPopupCloseButton = $phoneContactsPopup.querySelector('button.close');

		// Open the popup
		const openPhoneContact = () => {
			document.body.classList.add(PHONE_CONTACTS_ACTIVE_CLASS);
		};

		// Close the popup
		const closePhoneContact = () => {
			document.body.classList.remove(PHONE_CONTACTS_ACTIVE_CLASS);
		};

		// On close button click
		$phoneContactsPopupCloseButton.addEventListener('click', e => {
			e.preventDefault();
			closePhoneContact();
			return false;
		});

		// On backdrop click
		$phoneContactsPopup.addEventListener(
			'mousedown',
			e => (
				e.target === $phoneContactsPopup
			) ? closePhoneContact() : null
		);

		// Togglers click
		$phoneContactsTogglers.forEach($phoneContactToggler => {
			$phoneContactToggler.addEventListener('click', e => {
				e.preventDefault();
				const popupEvent = new Event('popup-opened');
				document.dispatchEvent(popupEvent);
				openPhoneContact();
				return false;
			});
		});
	}

	instituteCard() {
		const $instituteCardsImages = document.querySelectorAll('.instituteCard__image');
		if (!$instituteCardsImages) return;

		document.documentElement.style.removeProperty('--instituteCardImageHeight');

		const minHeight = Math.min.apply(null, [...$instituteCardsImages].map($instituteCardImage => $instituteCardImage.getBoundingClientRect().height));

		document.documentElement.style.setProperty('--instituteCardImageHeight', `${minHeight}px`);
	}

	/**
	 * Handle toolbox offscreen
	 */
	interactivePointsModule() {
		const $interactivePointsModule = document.querySelector('.interactiveModule__content--points');
		if (!$interactivePointsModule) return;

		const screenPadding = 35;

		const $toolboxs = $interactivePointsModule.querySelectorAll('.interactiveModule__content--points__image__points > * > div');
		$toolboxs.forEach($toolbox => {
			$toolbox.classList.remove('isOffscreen');
			const toolboxRect = $toolbox.getBoundingClientRect();
			const toolboxRightX = toolboxRect.x + toolboxRect.width;
			//@formatter:off
			if (toolboxRect.x < 0) {
				console.log('inférieur à 0');
			} else if ((toolboxRightX + screenPadding) > window.outerWidth) {
				$toolbox.classList.add('isOffscreen');
			}
			//@formatter:on
		});
	}

	/**
	 * Responsive management of interactive points module
	 */
	/**
	 * Responsive management of interactive points module
	 */
	responsiveInteractivePointsModule() {
		const $interactiveModule = document.querySelector('.interactiveModule__content--points__image');
		if (!$interactiveModule) return;

		const breakpoint = window.matchMedia('(max-width: 767px)');
		const $points = $interactiveModule.querySelectorAll('.interactiveModule__content--points__image__points > * > a:first-child');

		const getCloseBox = ($point) => $point.parentNode.querySelector('button[data-close-point-box]');

		const clickedPoint = (e) => {
			e.target.parentNode.classList.add('isActive');
		};
		const clickedDismissPoint = (e) => {
			e.target.parentNode.parentNode.classList.remove('isActive');
		};

		const pointsEvents = () => {
			[...$points].forEach($point => {
				$point.addEventListener('click', clickedPoint);
				const $closeBox = getCloseBox($point);
				if ($closeBox) {
					$closeBox.addEventListener('click', clickedDismissPoint);
				}
			});
		};

		const removePointsEvents = () => {
			[...$points].forEach($point => {
				$point.removeEventListener('click', clickedPoint);
				const $closeBox = getCloseBox($point);
				if ($closeBox) {
					$closeBox.removeEventListener('click', clickedDismissPoint);
				}
			});
		};

		const breakpointChecker = () => {
			if (breakpoint.matches === true) {
				pointsEvents();
			} else {
				removePointsEvents();
			}
		};

		breakpoint.addListener(breakpointChecker);
		breakpointChecker();
	}

	/**
	 * Background headband
	 */
	deepPageInteractiveModule() {
		const $interactiveModulePage = document.querySelector('.interactiveModulePage');
		if (!$interactiveModulePage) return;

		let height = 0;

		const $header = $interactiveModulePage.querySelector('.deepPage__sidebarContent__content__header');
		const headerHeight = parseFloat($header.getBoundingClientRect().height);

		if (headerHeight) {
			height += headerHeight;
		}

		const headerComputedStyle = window.getComputedStyle($header);
		const paddingBottom = parseFloat(headerComputedStyle.paddingBottom);

		if (paddingBottom) {
			height += paddingBottom;
		}

		const computedStyle = window.getComputedStyle($interactiveModulePage);
		const paddingTop = parseFloat(computedStyle.paddingTop);

		if (paddingTop) {
			height += paddingTop;
		}

		if (height > 0) {
			$interactiveModulePage.style.setProperty('--headbandHeight', `${height}px`);
		}
	}

	/**
	 * Handle anchor block
	 */
	anchorBlock() {
		if (!document.querySelector('.anchorBlock2')) return;

		new GumShoe('.anchorBlock2__links a', {
			reflow: true,
			nested: true,
			nestedClass: 'active-parent',
			offset: () => calcOffset()
		});
	}

	/**
	 * Initialization
	 */
	init() {
		new OutlineManager();
		this.initCustomElements();

		addMultipleEventListener(window, ['load', 'resize'], debounce(setHeaderHeight, 200));
		addMultipleEventListener(window, ['load', 'resize'], debounce(this.callToActionsHeight, 200));

		addMultipleEventListener(window, ['load', 'resize'], debounce(this.deepPageInteractiveModule, 200));
		addMultipleEventListener(window, ['load', 'resize'], debounce(this.interactivePointsModule, 200));

		addMultipleEventListener(window, ['load', 'resize'], debounce(this.instituteCard, 200));

		addMultipleEventListener(window, ['load', 'resize'], debounce(this.setIsResponsive, 200));

		addMultipleEventListener(window, ['load', 'resize'], debounce(this.deepPageHeaderHeight, 200));

		let resizeTimer;
		window.addEventListener("resize", () => {
			document.body.classList.add("resize-animation-stopper");
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				document.body.classList.remove("resize-animation-stopper");
			}, 400);
		});

		window.addEventListener('DOMContentLoaded', () => {
			this.initModules();
			if (document.body.classList.contains("single-post")) {
				var currentItem = document.querySelector(".current-item");

				if (currentItem) {
					var words = currentItem.textContent.split(' ');
					if (words.length > 3) {
						currentItem.textContent = words.slice(0, 3).join(' ') + '...';
					}
					// Rendre le texte visible après modification
					currentItem.style.visibility = 'visible';
				}
			}
		});
	}

	/**
	 * Init anchor block
	 */
	initAnchorBlock() {
		if (!document.querySelector('.anchorBlock')) {
			return;
		}

		new GumShoe('.anchorBlock__links a', {
			reflow: true,
			nested: true,
			nestedClass: 'active-parent',
			offset: () => calcOffset()
		});
	}

	/**
	 * Handle Accordion FAQ
	 */
	accordionBlocks() {
		const $accordionBlocks = document.querySelectorAll('.accordion_item');
		$accordionBlocks.forEach(function (el) {
			el.onclick = function () {
				const $content = el.querySelector('.accordion_content');
				if (el.matches('.active')) {
					DOMAnimations.slideUp($content);
					el.classList.remove('active');
				} else {
					DOMAnimations.slideDown($content);
					el.classList.add('active');
				}
			};
		});
	}

	/**
	 * Contact forms choices
	 */
	contactFormsChoices() {
		const $contactFormsChoices = document.querySelectorAll('[data-form-select]');

		if (!$contactFormsChoices) return;

		$contactFormsChoices.forEach($contactFormChoices => {
			const contactFormID = $contactFormChoices.getAttribute('data-form-id');
			const $contactForms = [...document.querySelectorAll(`[data-form-slug][data-form-id="${contactFormID}"]`)];

			$contactFormChoices.addEventListener('change', e => {
				$contactForms
					.filter($contactForm => $contactForm.hasAttribute('data-form-is-active'))
					.map($contactFormIsActive => $contactFormIsActive.removeAttribute('data-form-is-active'));

				$contactForms
					.find($contactForm => $contactForm.getAttribute('data-form-slug') === e.target.value)
					.setAttribute('data-form-is-active', '');
			});
		});
	}

	/**
	 * Handle Videos
	 */
	handleVideos() {
		const $videos = document.querySelectorAll('.videoPlayer');
		if (!$videos) {
			return;
		}

		$videos.forEach($video => {
			const $button = $video.querySelector('.videoPlayer__button button');
			const player = new Plyr($video.querySelector('.videoPlayer__video'));

			if ($button) {
				$button.addEventListener('click', () => {
					player.play();
					$video.classList.add('isActive');
				});
			}
		});
	}

	handleSearchBar(searchBarOpenedClass = 'searchBarIsActive') {
		const $searchBarOverlay = document.querySelector('.searchBarOverlay');
		let $togglers, $searchBar, $focusableElements;

		$togglers = [
			'header:not(.clone) [data-search-button]'
		];
		$searchBar = document.querySelector('.header__searchBar');
		$focusableElements = $searchBar.querySelectorAll(getFocusableElements());

		/**
		 * Close search bar
		 *
		 * Accessibility
		 *
		 * @param trigger
		 */
		const closeSearchBar = trigger => {
			document.body.classList.remove(searchBarOpenedClass);
			$searchBar.setAttribute('aria-hidden', 'true');
			$searchBar.setAttribute('tabindex', '-1');
			$focusableElements.forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));

			if (trigger) {
				setTimeout(() => trigger.focus(), 10);
			}
		};

		/**
		 * Open search bar
		 *
		 * - Accessibility
		 *
		 * @param trigger
		 */
		const openSearchBar = trigger => {
			document.body.classList.add(searchBarOpenedClass);
			$searchBar.setAttribute('aria-hidden', 'false');
			$searchBar.setAttribute('tabindex', '0');
			$focusableElements.forEach($focusableElement => $focusableElement.setAttribute('tabindex', '0'));

			// Escape key should close the search bar
			$searchBar.addEventListener('keydown', e => e.key === 'Escape' ? closeSearchBar(trigger) : null);

			// Close button should close the search bar
			addMultipleEventListener($searchBar.querySelector('.searchForm__close'), ['click', 'keydown'], e => {
				if (e.type === 'click' || (
					e.type === 'keydown' && e.key === 'Enter'
				)) {
					e.preventDefault();
					closeSearchBar(trigger);
				}
			});

			// Trap the focus inside
			const firstFocusableElement = $focusableElements[0];
			const lastFocusableElement = $focusableElements[$focusableElements.length - 1];

			if (!firstFocusableElement) {
				return;
			}
			window.setTimeout(() => {
				firstFocusableElement.focus();

				// Trapping focus inside the dialog
				$focusableElements.forEach(focusableElement => {
					if (focusableElement.addEventListener) {
						focusableElement.addEventListener('keydown', e => {
							const tab = e.key === 'Tab';
							if (!tab) {
								return;
							}

							if (e.shiftKey) {
								if (e.target === firstFocusableElement) { // Shift + Tab
									e.preventDefault();
									lastFocusableElement.focus();
								}
							} else if (e.target === lastFocusableElement) { // Tab
								e.preventDefault();
								firstFocusableElement.focus();
							}
						});
					}
				});
			}, 100);
		};

		// Element inside the search bar shouldn't be tabbable by default
		$focusableElements.forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));

		// Open search bar
		document.querySelectorAll($togglers).forEach($toggler => {
			$toggler.addEventListener('click', e => {
				e.preventDefault();
				if (document.body.classList.contains(searchBarOpenedClass)) {
					closeSearchBar($toggler);
				} else {
					openSearchBar($toggler);
				}
			});
		});

		// Close search bar on backdrop click
		$searchBarOverlay.addEventListener('click', e => {
			e.preventDefault();
			closeSearchBar();
		});
	}

	/**
	 * Call to actions height
	 */
	callToActionsHeight() {
		const $callToActions = document.querySelector('.desktopCta');
		if (!$callToActions) return;

		document.documentElement.style.removeProperty('--callToActionsHeight');
		const { height } = $callToActions.getBoundingClientRect();
		document.documentElement.style.setProperty('--callToActionsHeight', `${height}px`);
	}

	/**
	 * Set if the site is responsive or not
	 */
	setIsResponsive() {
		const $hamburger = document.querySelector('.header_right_bottom_hamburger');
		if ($hamburger.offsetWidth > 0 && $hamburger.offsetHeight > 0) {
			document.documentElement.setAttribute('data-is-responsive', '');
		} else {
			document.documentElement.removeAttribute('data-is-responsive');
		}
	}

	/**
	 * Is the website responsive ?
	 *
	 * @return boolean
	 */
	isResponsive() {
		return document.documentElement.hasAttribute('data-is-responsive');
	}

	/**
	 * Active menu items if there is no first level item active
	 */
	menuActiveItems() {
		if (document.body.classList.contains('appMenu__firstLevel--hasActiveItem')) return;

		let $activeItem = document.querySelector('.appMenu').querySelector('.appMenu__menuItem--isActive, .appMenu__isActivePage');
		if (!$activeItem) return;

		while ($activeItem.parentNode.closest('li')) {
			$activeItem = $activeItem.parentNode.closest('li');
			$activeItem.classList.add('appMenu__menuItem--isActive', 'appMenu__isActivePage');
		}

		document.body.classList.add('appMenu__firstLevel--hasActiveItem');
	}

	/**
	 * Change the default behavior of store locator website
	 */
	learnMoreStoreLocator() {
		const $storeLocatorLoading = document.querySelector('.store_locator_loading');
		if (!$storeLocatorLoading) return;

		let prevState = $storeLocatorLoading.classList.contains('store_locator_hidden');

		const observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				if (mutation.attributeName !== 'class') return;

				const currentState = mutation.target.classList.contains('store_locator_hidden');

				if (prevState === currentState) return;

				if (prevState !== currentState) {
					prevState = currentState;
					if (currentState) {
						const $storeLocatorWebsites = document.querySelectorAll('.store_locator_website');
						if (!$storeLocatorWebsites) return;
						[...$storeLocatorWebsites].forEach($storeLocatorWebsite => {
							console.log($storeLocatorWebsite);
						});
					}
				}
			});
		});

		observer.observe($storeLocatorLoading, {
			attributes: true,
			attributeOldValue: true,
			attributeFilter: ['class']
		});
	}

	deepPageHeaderHeight() {
		const $deepPage = document.querySelector('.deepPage__sidebarContent');
		if (!$deepPage) return;
		const $deepPageHeader = $deepPage.querySelector('.deepPage__sidebarContent__content__header');
		const $deepPageHeaderText = $deepPage.querySelector('.deepPage__sidebarContent__content__header + .textBlock');
		//@formatter:off
		const deepPageHeaderHeight = ($deepPageHeader && $deepPageHeader.getBoundingClientRect().height) + ($deepPageHeaderText && $deepPageHeaderText.getBoundingClientRect().height);
		//@formatter:on
		document.documentElement.style.setProperty('--deepPageHeaderHeight', `${deepPageHeaderHeight}px`);
	}

	/**
	 * Newsletter popup.
	 *
	 * @private
	 */
	_newsletterPopup() {
		const $newsletterPopupTogglers = document.querySelectorAll('[data-newsletter-button]');
		const $newsletterPopup = document.querySelector('.newsletterPopup');
		if ($newsletterPopupTogglers.length === 0 || !$newsletterPopup) return;

		const NEWSLETTER_POPUP_ACTIVE_CLASS = 'newsletterPopup--isActive'; // Body class to trigger the popup
		const $newsletterPopupCloseButton = $newsletterPopup.querySelector('.newsletterPopup__close');

		// Open the popup
		const openNewsletterPopup = () => {
			document.body.classList.add(NEWSLETTER_POPUP_ACTIVE_CLASS);
		};

		// Close the popup
		const closeNewsletterPopup = () => {
			document.body.classList.remove(NEWSLETTER_POPUP_ACTIVE_CLASS);
		};

		// On close button click
		$newsletterPopupCloseButton.addEventListener('click', e => {
			e.preventDefault();
			closeNewsletterPopup();
			return false;
		});

		// On backdrop click
		$newsletterPopup.addEventListener(
			'mousedown',
			//@formatter:off
			e => (e.target === $newsletterPopup) ? closeNewsletterPopup() : null
			//@formatter:on
		);

		// Togglers click
		$newsletterPopupTogglers.forEach($newsletterPopupToggler => {
			$newsletterPopupToggler.addEventListener('click', e => {
				e.preventDefault();
				openNewsletterPopup();
				return false;
			});
		});
	}

	/**
	 * Download book button.
	 *
	 * @private
	 */
	_buttonDownloadBook() {
		const $downloadBookButtons = document.querySelectorAll('.buttonSidebar--downloadBook');
		if ($downloadBookButtons.length === 0) {
			return;
		}

		$downloadBookButtons.forEach($downloadBookButton => {
			const bookId = $downloadBookButton.dataset.bookPopupId;
			const $popupStep1 = document.querySelector(`[data-book-popup-id="${bookId}"][data-book-popup-step="1"]`);
			const $popupStep1CloseButton = $popupStep1.querySelector('.downloadBookPopup__close');
			const $popupStep1DownloadButton = $popupStep1.querySelector('.downloadBookPopup__button');

			const $popupStep2 = document.querySelector(`[data-book-popup-id="${bookId}"][data-book-popup-step="2"]`);
			const $popupStep2CloseButton = $popupStep2.querySelector('.downloadBookPopup__close');

			$downloadBookButton.addEventListener('click', e => {
				e.preventDefault();
				$popupStep1.classList.add('isActive');
				return false;
			});

			$popupStep1DownloadButton.addEventListener('click', e => {
				e.preventDefault();
				$popupStep2.classList.add('isActive');
				return false;
			});

			// On close button click
			[$popupStep1CloseButton, $popupStep2CloseButton].forEach($close => {
				$close.addEventListener('click', e => {
					e.preventDefault();
					if ($close === $popupStep1CloseButton) {
						$popupStep1.classList.remove('isActive');
					}
					if ($close === $popupStep2CloseButton) {
						$popupStep2.classList.remove('isActive');
					}
					return false;
				});
			});

			// On backdrop click
			[$popupStep1, $popupStep2].forEach($popup => {
				$popup.addEventListener(
					'mousedown',
					//@formatter:off
					e => {
						if ($popup === $popupStep1 && e.target === $popupStep1) {
							$popupStep1.classList.remove('isActive');
						}
						if ($popup === $popupStep2 && e.target === $popupStep2) {
							$popupStep2.classList.remove('isActive');
						}
					}
					//@formatter:on
				);
			})


		});
	}

	/**
	 * Manage center page buttons.
	 *
	 * @private
	 */
	_centerPageButtons() {
		const $buttons = document.querySelectorAll('.centersPage__centers__buttons > button');
		const $storeLocator = document.querySelector('.centerPage__centers__search');

		if ($buttons.length === 0 && !$storeLocator) {
			return;
		}

		$buttons.forEach($button => {
			$button.addEventListener('click', e => {
				e.preventDefault();
				$storeLocator.dataset.centerPageShow = $button.dataset.centerPageShow;
				return false;
			});
		});
	}

	// Popup Postuler
	_popupPostuler() {
		const modalButtons = document.querySelectorAll('.recruitmentPage__callToActionButton a');
		const popupForm = document.querySelector('.antipodesForm');
		const overlays = document.querySelectorAll('.antipodes-contact-page__form-contact-overlay');
		const closeIcons = document.querySelectorAll('.close');

		if (!modalButtons || !popupForm || !overlays || !closeIcons) {
			return;
		}

		function togglePopup(event) {
			event.preventDefault();
			popupForm.classList.toggle('show');
		}

		modalButtons.forEach(button => button.addEventListener('click', togglePopup));
		closeIcons.forEach(icon => icon.addEventListener('click', togglePopup));
		overlays.forEach(overlay => overlay.addEventListener('click', togglePopup));
	}

	institutesPopupWithMaps() {
		const lightboxTriggers = document.querySelectorAll('[data-fslightbox]');
		const overlay = document.querySelector('.popup-doctolib-iframe-overlay');

		if (!lightboxTriggers && !overlay) {
			return;
		}

		lightboxTriggers.forEach((trigger) => {
			trigger.addEventListener('click', function (event) {
				event.preventDefault();
				const popupId = trigger.getAttribute('href').substring(1);
				const iframeUrl = trigger.getAttribute('iframe-url');
				const doctolibIframeUrl = iframeUrl.includes('part-doctolib') ? iframeUrl.replace('part-doctolib', 'partners.doctolib') : iframeUrl;
				const popupElement = document.getElementById(popupId);
				const body = document.querySelector('body');

				if (body.classList.contains('phoneContactsIsActive')) {
					body.classList.remove('phoneContactsIsActive');
				}

				if (popupElement) {
					let iframe = popupElement.querySelector('iframe');

					if (!iframe) {
						const spinner = document.createElement('div');
						spinner.className = 'loading-spinner';
						popupElement.appendChild(spinner);

						iframe = document.createElement('iframe');
						iframe.src = doctolibIframeUrl;
						iframe.classList.add('loading-iframe');
						popupElement.appendChild(iframe);

						iframe.addEventListener('load', () => {
							spinner.remove();
							iframe.classList.remove('loading-iframe');
						});

					} else {
						const spinner = popupElement.querySelector('.loading-spinner');
						if (spinner) spinner.remove();
					}

					popupElement.classList.add('active-popup');
					overlay.classList.add('active-overlay');

					const closeButton = popupElement.querySelector('.close-btn');
					if (closeButton) {
						closeButton.addEventListener('click', function () {
							popupElement.classList.remove('active-popup');
							overlay.classList.remove('active-overlay');
							const spinner = popupElement.querySelector('.loading-spinner');
							if (spinner) spinner.remove();
						});
					}
				}

				overlay.addEventListener('click', function (event) {
					popupElement.classList.remove('active-popup');
					overlay.classList.remove('active-overlay');
					const spinner = popupElement.querySelector('.loading-spinner');
					if (spinner) spinner.remove();
				});
			});
		});

	}

	/**
	 * Checks if any of the given queries match an element in the DOM.
	 * If at least one matches, dispatches a 'popup-opened' event.
	 *
	 * @param {string[]} queries - An array of CSS selectors to check for.
	 */
	_checkQueriesAndDispatchEvent(queries) {
		const exists = queries.some(query => document.querySelector(query));
		if (exists) {
			const popupEvent = new Event('popup-opened');
			document.dispatchEvent(popupEvent);
		}
	}
}

new App();
