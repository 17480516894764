import DOMAnimations from "./DOMAnimations";

export default class InteractiveAreasModule {

  constructor($element) {
    this.ACTIVE_AREA_CLASS = 'areaSvgIsActive';
    this.ACTIVE_TEXT_CLASS = 'isActive';
    this.ACTIVE_LOCALSTORAGE_ITEM_NAME = 'activeAreaId';

    this.$element = $element;
    this._cacheDOM();
    this.$areas = this._getAreas();
    this._init();
  }

  /**
   * @private
   */
  _cacheDOM() {
    this.$svg = this.$element.querySelector('svg');
    this.$texts = [...this.$element.querySelectorAll('[data-area-id]')];
  }

  /**
   * @private
   */
  _getAreas() {
    const dataAreasIds = this.$element.getAttribute('data-areas-ids');
    if (!dataAreasIds) {
      throw new Error('You need to specify data-areas-ids in the DOM to make the interactive module works.');
    }

    let $areasIdsInSvg = [];
    dataAreasIds.split(',').forEach(dataAreaId => {
      const $areaIdInSvg = this.$svg.getElementById(dataAreaId);
      if (!$areaIdInSvg) {
        throw new Error(`There is no ${dataAreaId} in the SVG.`);
      }
      $areasIdsInSvg.push($areaIdInSvg);
    });

    return $areasIdsInSvg;
  }

  /**
    * @private
    * @param {*} id 
    */
  _getTextOfArea(id) {
    const $text = this.$texts.find($text => $text.getAttribute('data-area-id') === id);
    if (!$text) {
      throw new Error(`The text with the data-area-id ${id} doesn't exists in the DOM.`);
    }
    return $text;
  }

   /**
    * @private
    * @param {*} id 
    */
  _getAreaOfText(id) {
    const $area = this.$areas.find($area => $area.getAttribute('id') === id);
    if (!$area) {
      throw new Error(`The area with the ID ${id} doesn't exists in the DOM SVG.`);
    }
    return $area;
  }
  
  /**
   * Activate AREA and TEXT based on the area ID
   * 
   * @param {*} id 
   */
  activeArea(id) {
    const $area = this._getAreaOfText(id);
    const $text = this._getTextOfArea(id);

    // Local storage
    localStorage.removeItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME);

    // Area
    [...this.$svg.querySelectorAll(`.${this.ACTIVE_AREA_CLASS}`)].forEach($activeArea => $activeArea.classList.remove(this.ACTIVE_AREA_CLASS));
    $area.classList.add(this.ACTIVE_AREA_CLASS);

    // Text
    this.$texts.forEach($text => {
      if (!$text.classList.contains(this.ACTIVE_TEXT_CLASS)) return;
      $text.classList.remove(this.ACTIVE_TEXT_CLASS);
      DOMAnimations.slideUp($text.querySelector('.interactiveModule__content--areas__texts__hover'));
    });
    $text.classList.add(this.ACTIVE_TEXT_CLASS);

    // Hover
    const $hover = $text.querySelector('.interactiveModule__content--areas__texts__hover');
    DOMAnimations.slideDown($hover);
  }

  /**
   * Return the active area ID
   */
  getActiveAreaId() {
    const $activeArea = this.$svg.querySelector(`.${this.ACTIVE_AREA_CLASS}`);
    return $activeArea ? $activeArea.getAttribute('id') : null;
  }

  /**
   * @private
   */
  _eventsOnTexts() {
    this.$texts.forEach($text => {
      const $title = $text.querySelector('.interactiveModule__content--areas__texts__title');
      
      $title.addEventListener('mouseover', () => {
        const $area = this._getAreaOfText($text.getAttribute('data-area-id'));
        const activeAreaId = this.getActiveAreaId();
        localStorage.setItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME, activeAreaId);

        if (activeAreaId) {
          [...this.$svg.querySelectorAll(`.${this.ACTIVE_AREA_CLASS}`)].forEach($activeArea => $activeArea.classList.remove(this.ACTIVE_AREA_CLASS));
        }
        $area.classList.add(this.ACTIVE_AREA_CLASS);
      });

      $title.addEventListener('mouseout', () => {
        const activeAreaId = localStorage.getItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME);
        if (activeAreaId === null) return;

        [...this.$svg.querySelectorAll(`.${this.ACTIVE_AREA_CLASS}`)].forEach($activeArea => $activeArea.classList.remove(this.ACTIVE_AREA_CLASS));

        if (activeAreaId !== 'null') {
          const $area = this.$svg.getElementById(activeAreaId);
          $area.classList.add(this.ACTIVE_AREA_CLASS);
        }

        localStorage.removeItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME);
      });

      $title.addEventListener('click', e => {
        e.preventDefault();
        this.activeArea($text.getAttribute('data-area-id'));
      });
    });
  }

  /**
   * @private
   */
  _eventsOnAreas() {
    this.$areas.forEach($area => {
      $area.addEventListener('mouseover', () => {
        const activeAreaId = this.getActiveAreaId();
        localStorage.setItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME, activeAreaId);

        if (activeAreaId) {
          [...this.$svg.querySelectorAll(`.${this.ACTIVE_AREA_CLASS}`)].forEach($activeArea => $activeArea.classList.remove(this.ACTIVE_AREA_CLASS));
        }
        $area.classList.add(this.ACTIVE_AREA_CLASS);
      });

      $area.addEventListener('mouseout', () => {
        const activeAreaId = localStorage.getItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME);
        if (activeAreaId === null) return;

        [...this.$svg.querySelectorAll(`.${this.ACTIVE_AREA_CLASS}`)].forEach($activeArea => $activeArea.classList.remove(this.ACTIVE_AREA_CLASS));

        if (activeAreaId !== 'null') {
          const $area = this.$svg.getElementById(activeAreaId);
          $area.classList.add(this.ACTIVE_AREA_CLASS);
        }

        localStorage.removeItem(this.ACTIVE_LOCALSTORAGE_ITEM_NAME);
      });

      $area.addEventListener('click', e => {
        e.preventDefault();
        this.activeArea($area.getAttribute('id'));
      })
    });
  }

  /**
   * @private
   */
  _init() {
    this._eventsOnAreas();
    this._eventsOnTexts();
  }

}