export default class SliderWithFilter {
    static init() {
        const $sliders = document.querySelectorAll(".institutesSliderWithFilter__slider");
        if ($sliders.length === 0) {
            return;
        }

        const config = {
            rewind: true,
            spaceBetween: 8,
            slidesPerView: 1,
            speed: 700,
            navigation: {
                nextEl: ".institutesSliderWithFilter__slider__navigationNext",
                prevEl: ".institutesSliderWithFilter__slider__navigationPrevious",
            },
            breakpoints: {
                650: {
                    slidesPerView: 2,
                },
                1100: {
                    slidesPerView: 3,
                },
                1400: {
                    slidesPerView: 4,
                }
            },
            on: {
                slideChange: function () {
                    updateSlideCounter(this, '.institutesSliderWithFilter');
                    updateProgress(this, '.institutesSliderWithFilter');
                },
                init: function () {
                    updateSlideCounter(this, '.institutesSliderWithFilter');
                    updateProgress(this, '.institutesSliderWithFilter');
                },
                resize: function () {
                    updateSlideCounter(this, '.institutesSliderWithFilter');
                    updateProgress(this, '.institutesSliderWithFilter');
                },
            }
        };

        let swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
        const filters = document.querySelectorAll(".institutesSliderWithFilter__header__filter-items span");

        function updateFilter(activeFilter) {
            const filters = document.querySelectorAll(".institutesSliderWithFilter__header__filter-items span");
            if (!activeFilter) {
                filters[0].classList.add("active");
                activeFilter = filters[0];
            }
            const filter = activeFilter.innerText.toLowerCase();
            Array.prototype.forEach.call(filters, function (el) {
                if (el === activeFilter) {
                    el.classList.add("active");
                } else {
                    el.classList.remove("active");
                }
            });
        }

        Array.prototype.forEach.call(filters, function (_filter) {
            _filter.addEventListener("click", function (e) {
                const self = e.target;
                const filter = self.getAttribute("data-filter").toLowerCase();
                updateFilter(self);
                console.log("filter:", filter);
                if (filter == "all") {
                    Array.prototype.forEach.call(
                        document.querySelectorAll(".institutesSliderWithFilter__slider .swiper-container [data-filter]"),
                        function (_item) {
                            _item.classList.remove("non-swiper-slide");
                            _item.classList.add("swiper-slide");
                        }
                    );
                    swiper.destroy();
                    swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
                } else {
                    Array.prototype.forEach.call(
                        document.querySelectorAll(
                            ".institutesSliderWithFilter__slider .swiper-container [data-filter]:not([data-filter='" + filter + "'])"
                        ),
                        (el) => {
                            el.classList.add("non-swiper-slide");
                            el.classList.remove("swiper-slide");
                            el.removeAttribute("style");
                        }
                    );
                    Array.prototype.forEach.call(
                        document.querySelectorAll(
                            ".institutesSliderWithFilter__slider .swiper-container [data-filter='" + filter + "']"
                        ),
                        (el) => {
                            el.classList.remove("non-swiper-slide");
                            el.classList.add("swiper-slide");
                            el.removeAttribute("style");
                        }
                    );
                    swiper.destroy();
                    swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
                }
            });
        });

        const selectFilters = document.querySelectorAll(".institutesSliderWithFilter__header__filter-items-mobile select");

        function updateSelectFilter(activeFilter) {
            const selectFilters = document.querySelectorAll(".institutesSliderWithFilter__header__filter-items-mobile select");
            if (!activeFilter) {
                selectFilters[0].classList.add("active");
                activeFilter = selectFilters[0];
            }

            Array.prototype.forEach.call(selectFilters, function (el) {
                if (el === activeFilter) {
                    el.classList.add("active");
                } else {
                    el.classList.remove("active");
                }
            });


            Array.prototype.forEach.call(selectFilters, function (_filter) {
                _filter.addEventListener("change", function (e) {
                    const self = e.target;
                    const filter = self.value.toLowerCase(); // Using the value of the selected option
                    updateSelectFilter(self);

                    if (filter === "all") {
                        Array.prototype.forEach.call(
                            document.querySelectorAll(".institutesSliderWithFilter__slider .swiper-container [data-filter]"),
                            function (_item) {
                                _item.classList.remove("non-swiper-slide");
                                _item.classList.add("swiper-slide");
                            }
                        );
                        swiper.destroy();
                        swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
                    } else {
                        Array.prototype.forEach.call(
                            document.querySelectorAll(
                                ".institutesSliderWithFilter__slider .swiper-container [data-filter]:not([data-filter='" + filter + "'])"
                            ),
                            (el) => {
                                el.classList.add("non-swiper-slide");
                                el.classList.remove("swiper-slide");
                                el.removeAttribute("style");
                            }
                        );
                        Array.prototype.forEach.call(
                            document.querySelectorAll(
                                ".institutesSliderWithFilter__slider .swiper-container [data-filter='" + filter + "']"
                            ),
                            (el) => {
                                el.classList.remove("non-swiper-slide");
                                el.classList.add("swiper-slide");
                                el.removeAttribute("style");
                            }
                        );
                        swiper.destroy();
                        swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
                    }
                });
            });
            updateFilter(null);
        }

        updateSelectFilter(null);

        function updateSlideCounter(swiper, selector) {
            let totalSlides = swiper.slides.length;
            const currentSlide = swiper.activeIndex + 1;
            const slidesPerView = swiper.slidesPerViewDynamic() - 1;
            const slideCounter = document.querySelector(`${selector} .current-slide`);
            const totalCounter = document.querySelector(`${selector} .total-slides`);

            if (slideCounter) {
                slideCounter.innerText = currentSlide;
            }
            if (totalCounter) {
                if ((totalSlides - slidesPerView) > 0) {
                    totalCounter.innerText = totalSlides - slidesPerView;
                } else {
                    totalCounter.innerText = 1;
                }
            }
        }

        function updateProgress(swiper, selector) {
            const slidesPerView = swiper.slidesPerViewDynamic() - 1;
            let totalSlides = 1;
            if ((swiper.slides.length - slidesPerView) > 0) {
                totalSlides = swiper.slides.length - slidesPerView;
            }
            const currentIndex = swiper.activeIndex + 1;
            const progress = ((currentIndex / totalSlides) * 100) - 100;
            const progressBar = document.querySelector(`${selector} .js-progress-bar`);

            if (progressBar) {
                progressBar.style.strokeDashoffset = progress * -1;
            }
        }

        const checkbox = document.querySelector('.institutesSliderWithFilter__switch');  // Assuming your checkbox has this class

        // Listen to checkbox state change
        checkbox.addEventListener('change', function () {
            filterItemsBasedOnCheckbox(checkbox);
        });

        // Apply filter based on checkbox state
        function filterItemsBasedOnCheckbox(checkbox) {
            const items = document.querySelectorAll(".institutesSliderWithFilter__slider .swiper-container .institutesMapsWithSearch__institute");

            if (checkbox.checked) {
                // Show only items with the class 'institut-with-balontherapie'
                Array.prototype.forEach.call(
                    document.querySelectorAll(".institutesSliderWithFilter__slider .swiper-container .swiper-slide:not(.institut-with-balontherapie)"),
                    (el) => {                                                                                           
                        el.classList.add("non-swiper-slide");
                        el.classList.remove("swiper-slide");
                        el.removeAttribute("style");
                    }
                );
                Array.prototype.forEach.call(
                    document.querySelectorAll(".institutesSliderWithFilter__slider .swiper-container .institut-with-balontherapie"),
                    (el) => {
                        el.classList.remove("non-swiper-slide");
                        el.classList.add("swiper-slide");
                        el.removeAttribute("style");
                    }
                );
                swiper.destroy();
                swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
            } else {
                Array.prototype.forEach.call(
                    document.querySelectorAll(".institutesSliderWithFilter__slider .swiper-container [data-filter]"),
                    function (_item) {
                        _item.classList.remove("non-swiper-slide");
                        _item.classList.add("swiper-slide");
                    }
                );
                swiper.destroy();
                swiper = new Swiper(".institutesSliderWithFilter__slider .swiper-container", config);
            }
        }

    }
}